/**
 * Created by yamamoto_taku on 16/10/20.
 * ドラッグして並べ替えられる一覧画面用の汎用 Backbone View クラス。
 * 内部的に SortableJS を利用している。
 * see also: https://github.com/RubaXa/Sortable
 */

///import _ from 'underscore/underscore';
// import Backbone from 'backbone/backbone';
import Sortable from 'sortablejs/Sortable';

var SortableTableView = Backbone.View.extend({
  // 以下のプロパティは本クラスのコンストラクタ引数経由でもオーバライド可能。

  // レコードのIDを格納した data-なんちゃら 属性の名前(後ろの部分)
  dataId: 'id', // <tr data-id="9393" ...>
  // レコードの行番号を格納した data-なんちゃら 属性の名前(後ろの部分)
  dataLineNo: 'line_no', // <tr data-line_no="72" ...>
  // つまみがある場合は、クラス名を jQuery のセレクタ形式で指定
  handleSelector: null, // or '.app-drag-knob' etc.

  initialize: function(options) {
    let opts = (options || {});
    if (opts.dataId) {
      this.dataId = opts.dataId;
    }
    if (opts.dataLineNo) {
      this.dataLineNo = opts.dataLineNo;
    }
    if (opts.handleSelector) {
      this.handleSelector = opts.handleSelector;
    }
    if (opts.onRenumber) {
      this.onRenumber = opts.onRenumber;
    }
  },

  render: function() {
    // http://stackoverflow.com/a/36506613
    this.sortable = Sortable.create(this.el, {
      animation: 150,
      scroll: true,
      handle: this.handleSelector,
      ghostClass: 'app-sortable-ghost',
      chosenClass: 'app-sortable-chosen',
      dragClass: 'app-sortable-drag',
      dataIdAttr: 'data-' + this.dataId,
      // メモ: アロー関数では self トリック不要(レキシカルな this)
      onUpdate: ev => this.onUpdate(ev),
    });
  },

  // ドラッグが離されたら呼ばれる
  onUpdate: function(ev) {
    ///console.dir(ev);
    let oldIndex = ev.oldIndex;
    let newIndex = ev.newIndex;
    // 実は移動されてなかった場合は処理を省略
    if (oldIndex == newIndex) {
      return;
    }
    // リナンバリングが必要な区間は、移動されたアイテムの元の位置と移動先の位置の間に限られる
    let startIndex = Math.min(oldIndex, newIndex);
    let endIndex = Math.max(oldIndex, newIndex) + 1;
    let ids = this.collectIds().slice(startIndex, endIndex);
    let lineNos = this.collectLineNos().slice(startIndex, endIndex);
    // レコードが先頭方向に移動された場合と末尾方向に移動された場合で若干処理が異なる
    if (oldIndex > newIndex) {
      // 先頭方向: 若くなくなってしまったリナンバリング区間先頭のアイテムを区間末尾に付け替え
      lineNos.push(lineNos.shift());
    } else {
      // 末尾方向: 若返ってしまったリナンバリング区間末尾のアイテムを区間先頭に付け替え
      lineNos.unshift(lineNos.pop());
    }
    // まず DOM に反映
    this.applyLineNos(startIndex, lineNos);
    // そしてサーバに反映
    this.onRenumber(lineNos,oldIndex,newIndex);
  },

  // 再採番完了 (サーバに反映させたり)
  // サブクラスでオーバライドするかコンストラクタ引数から function を与える
  onRenumber: function(ids, lineNos) {
    console.log(ids, lineNos);
  },

  // IDを回収
  collectIds: function() {
    return this._collectData(this.dataId);
  },

  // 行番号を回収
  collectLineNos: function() {
    return this._collectData(this.dataLineNo);
  },

  // 行番号を再適用: children[startIndex].data-line_no ← lineNos[0]
  applyLineNos: function(startIndex, lineNos) {
    this.$el.children().slice(startIndex).each((index, el) => {
      // メモ: アロー関数では self トリック不要(レキシカルな this)
      Backbone.$(el).data(this.dataLineNo, lineNos[index]);
    });
  },

  // data-* を回収
  _collectData: function(name) {
    return this.$el.children().map(
      (index, el) => Backbone.$(el).data(name)
    ).toArray();
  },
});

export default SortableTableView;
