(function(){
  const moment = require('moment')
  Gdt.OperationNewView = Marionette.View.extend({
    ui: {
      implant_operation_security_request_kbn: "#app-implant_operation_security_request_kbn",
      beauty_operation_security_request_kbn: "#app-beauty_operation_security_request_kbn",
      security_plan_table: "#app-security_plan_table",
      implant_operation_security_limit_kbn: "#implant_operation_security_limit_kbn",
      security_limit_kbn: "#app-security_limit_kbn",
      docterTableHeader: "#app-docter_th",
      submitButton: "#app-operation-submit-button",
      operationAt: "#app-operation-at",
      operationAtData: '#app-operation-at-data',
      resetRequestHiddenField: '#app-reset-request'
    },

    events: {
      "change @ui.implant_operation_security_request_kbn": "onImplantOperationSecurityKbn",
      "change @ui.beauty_operation_security_request_kbn": "onBeautyOperationSecurityKbn",
      "click @ui.submitButton": "onSubmit"
    },

    initialize: function() {
      this.onImplantOperationSecurityKbn();
      this.onBeautyOperationSecurityKbn();
    },

    //implantオペの保証希望のチェックボックスに対応する
    onImplantOperationSecurityKbn: function() {
      if(this.ui.implant_operation_security_request_kbn.prop('checked')) {
        //プランと上限のテーブルを表示
        this.ui.security_plan_table.show()
        //保証上限が１つの場合こっちでvalを設定し直す。(保証上限を1つしか許さなくしたので必ず走るようになった)
        var security_limit_kbn = this.ui.security_limit_kbn.data("security_limit_kbn")
        if(security_limit_kbn) {
          this.ui.implant_operation_security_limit_kbn.val(security_limit_kbn)
        }
        //保証をかける場合、担当医に必須マークをつける
        this.ui.docterTableHeader.append(
          "<span id='docter_hissu_mark', class=\"hissu\">*</span>"
        )
      }
      else {
        //プランと保証上限のテーブルを隠し、値を消す。
        this.ui.security_plan_table.hide()
        jQuery("#app-implant-plan-kbn-normal").children().prop('checked', false);
        jQuery("#app-implant-plan-kbn-hikari").children().prop('checked', false);
        jQuery("#app-implant-plan-kbn-yushinkai").children().prop('checked', false);
        this.ui.implant_operation_security_limit_kbn.val("")
        //担当医から必須マークを外す
        jQuery("#docter_hissu_mark").remove();
      }
    },

    //beautyオペの保証希望チェックボックス
    onBeautyOperationSecurityKbn: function() {
      if(this.ui.beauty_operation_security_request_kbn.prop('checked')) {
        //プランと上限のテーブルを表示
        this.ui.security_plan_table.show()
        //保証をかける場合、担当医に必須マークをつける
        this.ui.docterTableHeader.append(
          "<span id='docter_hissu_mark', class=\"hissu\">*</span>"
        )
      }
      else {
        //担当医から必須マークを外す
        jQuery("#docter_hissu_mark").remove();
      }
    },

    onSubmit: function(){
      const operationAtData = this.ui.operationAtData

      if (operationAtData) {
        // 医院からのオペ日変更リクエストを整理
        const securityRequested = operationAtData.data('security-requested')
        if (securityRequested) {
          // 保証オペのみ制限される
          const operationAt = moment(
            this.ui.operationAt.val(),
            "YYYY-MM-DD"
          );
          const deductiblePeriodMonthCount = operationAtData.data('deductible-period-month-count')
          const operationAtWas = moment(
            operationAtData.data('operationAtWas'),
            "YYYY-MM-DD"
          );
          const today = moment().startOf('day');

          // 免責期間完全経過
          //  バックエンド: operation_at.to_date + deductible_period_month_count.months <= date
          const overDeductioblePeriodFlag =
            deductiblePeriodMonthCount == 0
              ? false
              : operationAt.clone().add(deductiblePeriodMonthCount, "months") <= today;

          // 免責期間過半経過
          //   オペ歯登録のロジックに合わせる
          const overHalfOfDeductioblePeriodFlag =
            deductiblePeriodMonthCount == 0
              ? false
              : today.clone().diff(operationAt, "months", true) >=
              deductiblePeriodMonthCount / 2;

          let result = true;
          if (operationAt < today) {
            // 今日よりも過去に変更
            if (overDeductioblePeriodFlag) {
              alert("免責を完全超過したオペ日への変更はいたしかねます。\n大変お手数をおかけしますが、オペ情報詳細画面のオペ削除依頼をお願いいたします。");
              result = false;
            }
            else if (overHalfOfDeductioblePeriodFlag) {
              result = confirm("免責半分超過のオペ日への変更の場合は画像の登録及び弊社の承認が必要となります。\n大変お手数をおかけしますが、「OK」をご選択頂くと免責超過一覧に移動しますので、画像の登録をお願いいたします。");
            }
          } else if (operationAt > today) {
            // 今日よりも未来に変更
            if (operationAt > today.clone().endOf('month')) {
              // 元の値の翌月以降へ変更
              result = confirm("翌月以降のオペ日に変更することはできません。\nOKをクリック頂きますと未申請の状態に戻りますので、\nインプラント埋入(または審美補綴セット)後にオペ日を再度後設定ください。");
              if (result) { this.ui.resetRequestHiddenField.val(true) }
            }
            else {
              alert("未来のオペ日に変更することはできません。\nオペ実施日以降にオペ日の変更をお願いいたします。");
              result = false;
            }
          }
          if (!result) return; // オペ日変更が許可されないので中断
        }
      }

      // postするため希望チェックボックスを活性化
      // unchecked value を持っているhidden fieldも活性化させるためnameで指定
      jQuery('input[name="implant_operation[security_request_kbn]"]').prop('disabled', false)
      jQuery('input[name="beauty_operation[security_request_kbn]"]').prop('disabled', false)

      // 保証希望チェックボックスがチェックされていれば文章を出す。
      if (
        this.ui.implant_operation_security_request_kbn.prop('checked') ||
        this.ui.beauty_operation_security_request_kbn.prop('checked')
      ) {
        var confirm_str =
          "郵送にて患者様ご記入済みの申請書を当社宛へお送りください。\n到着後、確認させていただき不備等ございましたらご連絡させていただきます。"
        if (!confirm(confirm_str)) {
          return false;
        }
      }

      // 二度押し防止
      if (checkDouble()) {
        // 実際のサブミット
        jQuery("form").submit();
      }
    },
  });
})();
