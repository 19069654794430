(function(){
  var moment = require("moment");
  Gdt.OperationNewView = Marionette.View.extend({
    ui: {
      nameField: "#app-hospital-name-field",
      form: "#app-operation-common-form",
      PlanKbnNormal: "#app-implant-plan-kbn-normal",
      PlanKbnHikari: "#app-implant-plan-kbn-hikari",
      PlanKbnYushinkai: "#app-implant-plan-kbn-yushinkai",
      PlanKbnIC: "#app-implant-plan-kbn-ic",
      implantOperationForm: "#app-implant-operation-form",
      securityLimitKbnSelecter: "#app-security-limit-kbn-selecter"
    },

    events: {
      'click #app-search-hospital': 'searchHospital',
      'click .app-hospital-list': 'changeSelect',
      'click #app-hospital-dialog-submit': 'setHospitalDoctor',
      'click #app-operation-submit-button': 'submitAlert',
      'submit @ui.form': 'onSubmit'
    },

    initialize: function() {
      this.bindUIElements();
    },

    searchHospital: function(){
      var self = this
      //送信するurlを作る
      var url = '/admin/hospitals/search_hospital';
      //入力された医院名を取得
      var hospital_name = jQuery('#app-hospital-name').val()
      jQuery.ajax({
        type: 'get',
        url: url,
        data: {hospital_name: hospital_name},
      }).done(function(res){
        if (res.length == 0){
          alert('検索結果は０でした。')
        }else{
          self.setHospital(res)
        }
      }).fail(function(){
        alert('エラーが発生しました。')
      });
    },

    //医院をselect boxにsetする
    setHospital: function(res){
      //医院名を空にする
      jQuery('#app-hospital-list').empty();
      //検索で一致した医院をliにsetする
      this.hospitalMap = {};
      for (var i=0; i<res.length; i++){
        this.hospitalMap[res[i].hospital_id] = res[i];

        var hospitalName = res[i].hospital_name + " " + "（"+ Gdt.Util.getPrefectureName(res[i].prefecture_kbn) + " " + res[i].city_address.slice(0, 4)  + " " + (res[i].city_address.length > 4 ? "..." : "") + "）";
        jQuery('#app-hospital-list').append(
          "<li>" +
            "<label id='app-hospital-label'>" +
              "<input type='radio' " + "name='hospital'" + "value=" + "'" + res[i].hospital_id + "'" + "class='app-hospital-list'" + ">" +
                hospitalName +
              "</input>" +
            "</label>" +
          "</li>" +
          "<br />"
        );
      }

      //医師選択のselect boxを表示するかしないか決定する
      this.createDoctorSelectBox();
      //doctorのselect boxをresetする
      jQuery('select#app-doctor-id-select option').remove();
      jQuery('#app-doctor-id-select').append($("<option>").val('').text('医師選択'));
    },

    changeSelect: function(e){
      //選択されたvalueを取得
      var selectVal = jQuery(e.target).val();
      //医師選択のselect boxを一括削除
      jQuery('select#app-doctor-id-select option').remove();
      jQuery('#app-doctor-id-select').append($("<option>").val('').text('医師選択'));
      var hospital = this.hospitalMap[selectVal];
      var doctors = hospital.doctors_jsonb;
      for (var i=0; i<doctors.length; i++){
        jQuery('#app-doctor-id-select').append($("<option>").val(doctors[i].id).text(doctors[i].doctor_name));
      }
    },

    chagePlanKbnRadioButton: function(implantPlanKbnAry){
      var self = this;
      //plan_kbn
      //00501: 通常//00502: 光//00503: 有心会//00504: IC
      //一旦全部隠す
      jQuery("#app-implant-plan-kbn-normal").hide();
      jQuery("#app-implant-plan-kbn-normal").children().prop('checked', false);
      jQuery("#app-implant-plan-kbn-hikari").hide();
      jQuery("#app-implant-plan-kbn-hikari").children().prop('checked', false);
      jQuery("#app-implant-plan-kbn-yushinkai").hide();
      jQuery("#app-implant-plan-kbn-yushinkai").children().prop('checked', false);
      jQuery("#app-implant-plan-kbn-ic").hide();
      jQuery("#app-implant-plan-kbn-ic").children().prop('checked', false);
      //配列の中にあるkbnのみ表示する
      _.each(implantPlanKbnAry, function(kbn){
        if (kbn == '00501'){
          self.ui.PlanKbnNormal.show();
        }else if (kbn == '00502'){
          self.ui.PlanKbnHikari.show();
        }else if (kbn == '00503') {
          self.ui.PlanKbnYushinkai.show();
        }else {
          self.ui.PlanKbnIC.show();
        }
      });
    },

    changeSecurityLimitSelectBox: function(securityLimitKbnAry){
      //保証上限//01901: 保証上限あり//01902: 保証上限なし
      jQuery("#implant_operation_form_security_limit_kbn").remove();
      if (securityLimitKbnAry.length == 1 ){
        var security_limit_kbn = securityLimitKbnAry[0];
        var securityLimitName = security_limit_kbn == '01901' ? 'あり' : 'なし'
        //kbnの配列が1要素しかない場合はhidden_fieldにする
        this.ui.securityLimitKbnSelecter.append(
          "<span id='implant_operation_form_security_limit_kbn'>" +
            securityLimitName +
            "<input value=" + "'" + security_limit_kbn + "'" + "type='hidden'" + "name=implant_operation_form[security_limit_kbn]" + ">" +
          "</span>"
        );
      }else if (securityLimitKbnAry.length == 2){
        this.ui.securityLimitKbnSelecter.append(
          "<select class='form-select'" + "name=" + "'" + "implant_operation_form[security_limit_kbn]" + "'" + "id=implant_operation_form_security_limit_kbn" + ">" +
            "<option value='01901'>あり</option>" +
            "<option value='01902'>なし</option>" +
          "</select>"
        );
      }
    },

    createDoctorSelectBox: function(){
      //this.hospitalMapに何か値が入っていると検索されていると判断
      if (Object.keys(this.hospitalMap).length){
        jQuery('#app-doctor-selct-p').remove()
        jQuery('#app-doctor-selecter').prepend(
          "<p id='app-doctor-selct-p'>" +
            "<select id='app-doctor-id-select' class='form-select'>" +
              "<option>" +
                "医師選択" +
              "</option>" +
            "</select>" +
          "</p>");
      }else{ //検索が一致していないから医師select boxを削除
        jQuery('#app-doctor-selct-p').remove()
      }
    },

    setHospitalDoctor: function(){ //ダイアログを閉じ、医院と医師をセットする
      var hospitalObjs = this.hospitalMap
      //入力値を取得
      var doctorId = jQuery('#app-doctor-id-select option:selected').val();
      var hospitalId = jQuery("[name=hospital]:radio:checked").val();
      //hospital_idからオブジェクトを取得
      if (!( doctorId == undefined|| doctorId == '' || hospitalId == undefined || hospitalId == '')){
        var hospitalObj = hospitalObjs[hospitalId];
        var doctors = hospitalObj.doctors_jsonb
        var doctor = _.find(doctors, function(it){
          return it.id == doctorId
        })
        
        jQuery("#app-doctor-id-field").val(doctorId);
        jQuery("#app-doctor-name-field-hidden").val(doctor.doctor_name);
        jQuery("#app-doctor-name-field").text(doctor.doctor_name);
        jQuery("#app-hospital-id-field").val(hospitalId);
        jQuery("#app-hospital-name-field-hidden").val(hospitalObj.hospital_name);
        jQuery("#app-hospital-name-field").text(hospitalObj.hospital_name);
        jQuery('.overlay').hide();
        //インプラント処置登録/更新のときのみこの処理をする
        if (this.ui.implantOperationForm.length){
          //プラン区分のradio_buttonを決める
          this.chagePlanKbnRadioButton(hospitalObj.implant_plan_kbn_ary);
          //上限有無も
          this.changeSecurityLimitSelectBox(hospitalObj.security_limit_kbn_ary);
        }
      }else{
        alert('医院もしくは医師を入力してください')
      }
    },

    // 送信するときにアラートを出す
    submitAlert: function(){

      // 登録当日の日付が当月の締め日より前、かつオペ日が当月の場合、確認ダイアログを表示する（作成および更新）
      var now = moment()
      var closing_at = moment(this.ui.nameField.data("closing-at"));
      var operation_at = moment(jQuery('#app-operation-at').val());
      var confirmStr = ''
      if (now < closing_at && now.format('YYYYMM') === operation_at.format('YYYYMM')) {
        confirmStr = confirmStr + 'このオペの保証書は来月に発行されます。'
      }

      // 配下に歯が登録されている場合、確認ダイアログを表示する（更新）
      var operationCount = jQuery('#app-operation-teeth-count').data('operation-count');
      if (operationCount > 0 ){
        if (confirmStr !== ''){
          confirmStr = confirmStr + '\n'
        }
        confirmStr = confirmStr + 'オペ歯が登録されています。更新しますか？'
      }

      if (confirmStr !== ''){
        if (!confirm(confirmStr)){
          return false
        }
      }
      this.getUI('form').submit();
    },

    onSubmit: function() {
      return checkDouble();
    },

  });
})();
