// フォームバリデーションモジュール

const msg = {
  required:
    '<div class="error_msg" data-validate-msg="required">必須項目です</div>',
  required_group:
    '<div class="error_msg" data-validate-msg="required_group">1つ以上入力必須です</div>',
  numeric:
    '<div class="error_msg" data-validate-msg="numeric">半角数字で入力してください</div>',
};

// テーブルセルにエラーを追加する
function addError(element, validation) {
  element.addClass("error");
  let cell = element.data("required-group")
    ? $(`[data-required-group="${element.data("required-group")}"]`)
        .first()
        .closest("td")
    : element.closest("td");
  if (!cell.children(`[data-validate-msg="${validation}"]`).length) {
    cell.prepend(msg[validation]);
  }
}

// テーブルセルからエラーを削除する
function removeError(element, validation) {
  if (!validation) {
    element.removeClass("error");
    element.closest("td").children("[data-validate-msg]").remove();
  } else {
    let cell = element.data("required-group")
      ? $(`[data-required-group="${element.data("required-group")}"]`)
          .first()
          .closest("td")
      : element.closest("td");

    cell.children(`[data-validate-msg="${validation}"]`).remove();

    if (!element.closest("td").children("[data-validate-msg]").length) {
      element.removeClass("error");
    }
  }
}

// 指定要素のバリデーションを行い、エラーメッセージを追加削除する
function validate(element) {
  // 要素が非表示および無効の場合はエラーをすべて削除
  if (element.is(":hidden") || element.is(":disabled")) {
    removeError(element);
    return;
  }

  let validations = element.data("validate");

  // 必須入力（単一フォーム）
  if (validations.includes("required")) {
    if (!element.val()) {
      addError(element, "required");
    } else {
      removeError(element, "required");
    }
  }

  // 必須入力（複数）
  if (validations.includes("required_group")) {
    let elements = $(
      `[data-required-group='${element.data("required-group")}']`
    );
    let values = elements
      .map(function (i, e) {
        if ($(e).prop("type") === "checkbox" || $(e).prop("type") === "radio") {
          if ($(e).is(":checked")) {
            return $(e).val();
          }
        } else {
          return $(e).val();
        }
      })
      .get();
    if (!values.length || values.every((v) => !v)) {
      addError(element, "required_group");
    } else {
      removeError(element, "required_group");
    }
  }

  // 数値
  if (validations.includes("numeric")) {
    if (element.val() && !element.val().match(/^(\d*|\d{1,3}(,\d{3})*)$/)) {
      addError(element, "numeric");
    } else {
      removeError(element, "numeric");
    }
  }
}

module.exports = {
  // バリデーションイベントを登録
  init: function () {
    $("[data-validate]").on("change focusout", function (e) {
      let element = $(this);
      validate(element);
    });
  },
  // 要素のエラーを削除
  clearError: function (element) {
    removeError(element);
  },
  // すべての要素にバリデーションを実行
  validateAll: function () {
    $("[data-validate]").each(function (index, element) {
      if (!$(element).is(":hidden") && !$(element).is(":disabled")) {
        validate($(element));
      }
    });
  },
};
