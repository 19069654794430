(function () {
  var moment = require("moment");
  //保証残額 max_price
  //確定金額 beauty_1_3_priceとか
  Gdt.BeautyOperationToothNewView = Marionette.View.extend({
    BEAUTY_CATEGORY_KBN_SECURITY: "00101",
    BEAUTY_CATEGORY_KBN_LIGHT: "00102",
    FIXTURE_TYPE_KBN_IMPLANT: "02801",

    initialize: function () {
      //tooth_codeを定義
      this.beauty_1_3Price = [
        "右上3’",
        "右上3",
        "右上2’",
        "右上2",
        "右上1’",
        "右上1",
        "上0",
        "左上1",
        "左上1’",
        "左上2",
        "左上2’",
        "左上3",
        "左上3’",
        "右下3’",
        "右下3",
        "右下2’",
        "右下2",
        "右下1’",
        "右下1",
        "下0",
        "左下1",
        "左下1’",
        "左下2",
        "左下2’",
        "左下3",
        "左下3’",
      ];
      this.beauty_4_5Price = [
        "右上5’",
        "右上5",
        "右上4’",
        "右上4",
        "左上4",
        "左上4’",
        "左上5",
        "左上5’",
        "右下5’",
        "右下5",
        "右下4’",
        "右下4",
        "左下4",
        "左下4’",
        "左下5",
        "左下5’",
      ];
      this.beauty_6_7Price = [
        "右上8’",
        "右上8",
        "右上7’",
        "右上7",
        "右上6’",
        "右上6",
        "左上6",
        "左上6’",
        "左上7",
        "左上7’",
        "左上8",
        "左上8’",
        "右下8’",
        "右下8",
        "右下7’",
        "右下7",
        "右下6’",
        "右下6",
        "左下6",
        "左下6’",
        "左下7",
        "左下7’",
        "左下8",
        "左下8’",
      ];
      this.beautyPlans = JSON.parse(jQuery("#app-beauty-plans").text());
      this.teeth = JSON.parse(jQuery("#app-tooth-obj").text());
      this.beautyOperation = JSON.parse(jQuery("#app-beauty-operation").text());
      // Adminの場合存在しない。
      if (jQuery("#app-fixtures").text()) {
        this.fixtures = JSON.parse(jQuery("#app-fixtures").text());
      }
      this.onChangeMaterial();

      // 確定済みの場合、備考のみ編集可能
      if ("1" == this.$(".app-form-beauty-operation-tooth").data("fixed")) {
        this.$("input[type=radio]").prop("disabled", true);
        this.$("select").prop("disabled", true);
        this.$("input[type=checkbox]").prop("disabled", true);
      }

      //処置歯の画面ではfont sizeを16pxにするらしい;
      document.body.style.fontSize = "16px";
    },

    ui: {
      twiceFlag: "#app-twice-flag",
      toothCode: "#app-beauty-operation-tooth-tooth-id",
      toothIdSelect: "#beauty_operation_tooth_form_tooth_id",
      beautyMaterials: "#app-beauty_material_master_detail_id",
      beautyCategorys:
        "[name='beauty_operation_tooth_form[beauty_category_kbn]']",
      beautyMaxPrice: "[name='beauty_operation_tooth_form[max_price]']",
      beautyImplantFlag: "#app-beauty-implant-flag",
    },

    events: {
      "change #app-fixture": "setRemainingAndDecisionPrice",
      "click #app-beauty-operation-tooth-submit": "alertSubmit",
      "change @ui.toothIdSelect": "setRemainingAndDecisionPrice",
      "change @ui.beautyMaterials": "onChangeMaterial",
      "change @ui.beautyCategorys": "onChangeCategory",
      "change @ui.beautyMaxPrice": "setRemainingAndDecisionPrice",
      "change @ui.beautyImplantFlag": "setRemainingAndDecisionPrice",
    },

    // プラン一覧から材質の一致するものを回収
    setBeautyPlanByMatelial: function () {
      return _.filter(this.beautyPlans, function (beautyPlan) {
        return (
          beautyPlan.beauty_material_master_detail_id ==
          jQuery("#app-beauty_material_master_detail_id option:selected").val()
        );
      });
    },

    // 材質とカテゴリで絞る
    setBeautyPlanByMatelialAndCategory: function () {
      return _.filter(this.beautyPlans, function (beautyPlan) {
        return (
          beautyPlan.beauty_material_master_detail_id ==
            jQuery(
              "#app-beauty_material_master_detail_id option:selected"
            ).val() &&
          beautyPlan.beauty_category_kbn ==
            jQuery(
              "[name='beauty_operation_tooth_form[beauty_category_kbn]']:checked"
            ).val()
        );
      });
    },

    // 材質、カテゴリ、上限、3種全てで絞る
    setBeautyPlanByAll: function () {
      return _.find(this.beautyPlans, function (beautyPlan) {
        return (
          beautyPlan.beauty_material_master_detail_id ==
            jQuery(
              "#app-beauty_material_master_detail_id option:selected"
            ).val() &&
          beautyPlan.beauty_category_kbn ==
            jQuery(
              "[name='beauty_operation_tooth_form[beauty_category_kbn]']:checked"
            ).val() &&
          Math.trunc(beautyPlan.max_price) ==
            jQuery(
              "[name='beauty_operation_tooth_form[max_price]']:checked"
            ).val()
        );
      });
    },

    // 材質で決まったプランカテゴリでラジオボタンの状態を変える。
    onChangeMaterial: function () {
      // 材質で絞ったプランからカテゴリ区分だけ回収
      var beauty_category_kbns = this.setBeautyPlanByMatelial().map(function (
        plan
      ) {
        return plan.beauty_category_kbn;
      });
      // あんしんがあれば活性化、なければ失活＆値消去
      if (beauty_category_kbns.includes(this.BEAUTY_CATEGORY_KBN_SECURITY)) {
        jQuery("#app-beauty-plan-anshin").prop("disabled", false);
      } else {
        jQuery("#app-beauty-plan-anshin").prop("checked", false);
        jQuery("#app-beauty-plan-anshin").prop("disabled", true);
      }
      // ライトがあれば活性化、なければ失活＆値消去
      if (beauty_category_kbns.includes(this.BEAUTY_CATEGORY_KBN_LIGHT)) {
        jQuery("#app-beauty-plan-light").prop("disabled", false);
      } else {
        jQuery("#app-beauty-plan-light").prop("checked", false);
        jQuery("#app-beauty-plan-light").prop("disabled", true);
      }
      this.onChangeCategory();
    },

    // 材質とプランカテゴリで決まったプラン上限でラジオボタンの状態を変える。
    onChangeCategory: function () {
      var category_selected_plans = this.setBeautyPlanByMatelialAndCategory();
      // 上限金額だけ回収
      var max_prices = category_selected_plans.map(function (plan) {
        return Math.trunc(plan.max_price);
      });

      if (max_prices.includes(100000)) {
        jQuery("#app-max-price-ten-radio-button").prop("disabled", false);
      } else {
        jQuery("#app-max-price-ten-radio-button").prop("checked", false);
        jQuery("#app-max-price-ten-radio-button").prop("disabled", true);
      }
      if (max_prices.includes(50000)) {
        jQuery("#app-max-price-five-radio-button").prop("disabled", false);
      } else {
        jQuery("#app-max-price-five-radio-button").prop("checked", false);
        jQuery("#app-max-price-five-radio-button").prop("disabled", true);
      }
      this.setRemainingAndDecisionPrice();
    },

    // 材質、カテゴリ、上限金額の３要素で一致を計る。
    setRemainingAndDecisionPrice: function () {
      var beautyPlan = this.setBeautyPlanByAll();
      // プランが決まらない(3要素に欠けがある)場合は終了する
      if (!beautyPlan) {
        jQuery("#app-beauty-operation-tooth-remaining-price").text("");
        jQuery("#app-decision-price").text("");
        return;
      }
      this.setRemainingPrice(beautyPlan);
      this.setDecisionPrice(beautyPlan);
    },

    setRemainingPrice: function (beautyPlan) {
      jQuery("#app-beauty-operation-tooth-remaining-price").text(
        Math.trunc(beautyPlan.max_price) + "円"
      );
    },

    getBeautyPriceKey: function (toothCode) {
      var flag1 = this.beauty_1_3Price.includes(toothCode);
      var flag2 = this.beauty_4_5Price.includes(toothCode);
      var key = "beauty_6_7_price";
      if (flag1) {
        key = "beauty_1_3_price";
      } else if (flag2) {
        key = "beauty_4_5_price";
      }
      return key;
    },

    setDecisionPrice: function (beautyPlan) {
      //登録されようとしているtooth_idから歯オブジェクトを取得
      var toothId = this.ui.toothIdSelect.val();
      var toothObj = this.findTeeth(toothId);
      var taxRate = this.beautyOperation.tax_rate;
      if (this.beauty_1_3Price.includes(toothObj.tooth_code)) {
        var decisionPrice = Math.trunc(Number(beautyPlan.beauty_1_3_price));
      } else if (this.beauty_4_5Price.includes(toothObj.tooth_code)) {
        var decisionPrice = Math.trunc(Number(beautyPlan.beauty_4_5_price));
      } else if (this.beauty_6_7Price.includes(toothObj.tooth_code)) {
        var decisionPrice = Math.trunc(Number(beautyPlan.beauty_6_7_price));
      }

      var selectedFixture = this.findFixture(
        jQuery("#app-fixture option:selected").val()
      );
      const rate = // 審美7年プランのときは加算なし
        jQuery("#app-beauty-plan-seven1").prop("checked") ||
        jQuery("#app-beauty-plan-seven2").prop("checked") ||
        jQuery("#app-beauty-plan-seven3").prop("checked") ? 0 : 1
      if (selectedFixture) {
        // フィクスチャーが選択されていればインプラント代計算
        decisionPrice +=
          selectedFixture.fixture_type_kbn == this.FIXTURE_TYPE_KBN_IMPLANT
            ? 1000 * rate
            : 0;
      } else {
        // 選択されていなければフラグチェックボックスを確認
        decisionPrice += this.ui.beautyImplantFlag.prop("checked") ? 1000 * rate : 0;
      }
      jQuery("#app-decision-price").text(
        String(decisionPrice) +
          "　" +
          "(" +
          String(Math.trunc(decisionPrice * taxRate)) +
          ")" +
          "円"
      );
      $("#beauty_operation_tooth_form_decision_price").val(decisionPrice);
      $("#beauty_operation_tooth_form_decision_price_with_tax").val(
        decisionPrice * taxRate
      );
    },

    findFixture: function (fixtureId) {
      return _.find(this.fixtures, function (it) {
        return it.id == fixtureId;
      });
    },

    findTeeth: function (toothId) {
      var obj = _.find(this.teeth, function (it) {
        return it.id == toothId;
      });
      return obj;
    },

    alertSubmit: function () {
      var confirm_str = "";

      var operatorAtPassFlag = jQuery("#app-beauty-operation-pass-flag").data(
        "operation-pass-flag"
      );
      confirm_str = !operatorAtPassFlag
        ? "オペ・治療の予定日を過ぎていません。内容を確認後の登録ですか？\n"
        : "";

      var twiceFlag = this.ui.twiceFlag.data("twice-flag");
      confirm_str =
        confirm_str +
        (twiceFlag
          ? "一本の歯に複数のオペを登録しようとしています。よろしいですか？\n"
          : "");

      var beautyPlan = this.setBeautyPlanByAll();
      if (beautyPlan) {
        // プランが決まればオペ実施日時と選択された保証プランの免責期間(月数)、現在日時を取得
        var operation_at = moment(
          this.beautyOperation.operation_at,
          "YYYY-MM-DD"
        );
        var selectBeautyPlanDeductiblePeriod =
          beautyPlan.deductible_period_month_count;
        var now = moment();
        //オペ後日数の免責期間過半経過フラグ
        //現在日時-オペ日 >= 免責期間/2 なら免責期間の半分以上経過しているとしてtrueを返す。
        // ただし、選択されたプランの免責期間が0ヶ月の場合false。
        var overHalfOfDeductioblePeriodFlag =
          selectBeautyPlanDeductiblePeriod == 0
            ? false
            : now.diff(operation_at, "months", true) >=
              selectBeautyPlanDeductiblePeriod / 2;
        const imagesPresent = jQuery('#app-data').data('images-present');
        const rejoin = jQuery('#app-data').data('rejoin');
        confirm_str =
          confirm_str +
          (!imagesPresent && !rejoin && overHalfOfDeductioblePeriodFlag ? "「画像登録」から画像を登録してください。" : "");
      }

      if (confirm_str !== "") {
        if (!confirm(confirm_str)) {
          return false;
        }
      }

      // 二度押し防止
      if (checkDouble()) {
        // 実際のサブミット
        jQuery("form").submit();
      }
    },
  });
})();
