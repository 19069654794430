// --------------------------------------------------
// 再治療内容 費用入力フォーム
// --------------------------------------------------

const validator = require("./validator");

// selectタグ用のオプションを取得する
function getOptionsForSelect(retreatmentType, moduleName, parentKey) {
  if (
    retreatmentType !== "implant_upper" &&
    retreatmentType !== "implant_body" &&
    retreatmentType !== "beauty"
  ) {
    throw new Error(
      'Unexpected retreatmentType. retreatmentType has to be "implant_upper", "implant_body" or "beauty"'
    );
  }
  if (retreatmentType == "implant_upper") {
    if (moduleName === "part_1") {
      return [
        { text: "選択してください", value: "" },
        { text: "固定性補綴物", value: "fixed_prosthesis" },
        { text: "可撤性補綴物（主に義歯）", value: "removable_prosthesis" },
      ];
    } else if (moduleName === "part_2") {
      if (parentKey === "fixed_prosthesis" || !parentKey) {
        return [
          { text: "選択してください", value: "" },
          { text: "補綴装置", value: "prosthesis" },
          { text: "その他", value: "other" },
        ];
      } else if (parentKey === "removable_prosthesis") {
        return [
          { text: "選択してください", value: "" },
          { text: "マグネット義歯", value: "magnet_denture" },
          { text: "オーリング義歯", value: "o_ring_denture" },
          { text: "バーアタッチメント義歯", value: "bar_attachment_denture" },
          { text: "その他", value: "other" },
        ];
      }
    } else if (moduleName === "cause") {
      if (parentKey === "fixed_prosthesis.prosthesis") {
        return [
          { text: "選択してください", value: "" },
          {
            text: "補綴装置の金属（ジルコニア・アルミナ ハイブリッド）の破折",
            value: "prosthesis_fructure",
          },
          { text: "前装材料の破折", value: "face_fructure" },
          { text: "前装材料の剥離", value: "face_exfoliation" },
        ];
      } else if (parentKey === "removable_prosthesis.magnet_denture") {
        return [
          { text: "選択してください", value: "" },
          { text: "マグネット脱離による紛失", value: "magnet_desorption" },
          { text: "マグネットの劣化", value: "magnet_degradation" },
        ];
      } else if (parentKey === "removable_prosthesis.o_ring_denture") {
        return [
          { text: "選択してください", value: "" },
          { text: "オーリングの劣化", value: "o_ring_degradation" },
          { text: "アバットメント側の破折", value: "abutment_side_fracture" },
        ];
      } else if (parentKey === "removable_prosthesis.bar_attachment_denture") {
        return [
          { text: "選択してください", value: "" },
          { text: "クリップの緩み", value: "clip_loose" },
          { text: "クリップの破折", value: "clip_fracture" },
          { text: "バーの破折", value: "bar_fracture" },
        ];
      }
    } else if (moduleName === "operation") {
      if (parentKey === "fixed_prosthesis.prosthesis.prosthesis_fructure") {
        return [
          { text: "選択してください", value: "" },
          { text: "上部構造再製作", value: "upper_structure_reproduction" },
          { text: "再印象", value: "reimpression" },
          { text: "バイト", value: "bite" },
        ];
      } else if (parentKey === "fixed_prosthesis.prosthesis.face_fructure") {
        return [
          { text: "選択してください", value: "" },
          { text: "破折部位の研磨", value: "polishing" },
          { text: "削合による形態修正", value: "recountering" },
          { text: "再製作", value: "reproduction" },
          { text: "修理", value: "fix" },
        ];
      } else if (
        [
          "removable_prosthesis.magnet_denture",
          "fixed_prosthesis.prosthesis.face_exfoliation",
          "fixed_prosthesis.prosthesis.face_discoloration",
          "removable_prosthesis.magnet_denture.magnet_desorption",
          "removable_prosthesis.magnet_denture.magnet_degradation",
          "removable_prosthesis.o_ring_denture.o_ring_degradation",
          "removable_prosthesis.o_ring_denture.abutment_side_fracture",
          "removable_prosthesis.bar_attachment_denture.clip_loose",
          "removable_prosthesis.bar_attachment_denture.clip_fracture",
          "removable_prosthesis.bar_attachment_denture.bar_fracture",
        ].includes(parentKey)
      ) {
        return [
          { text: "選択してください", value: "" },
          { text: "再製作", value: "reproduction" },
          { text: "修理", value: "fix" },
        ];
      }
    } else if (moduleName === "material") {
      if (
        [
          "fixed_prosthesis.prosthesis.face_fructure.reproduction",
          "fixed_prosthesis.prosthesis.face_fructure.fix",
        ].includes(parentKey)
      ) {
        return [
          { text: "選択してください", value: "" },
          { text: "再製作用印象材", value: "impression_material" },
          { text: "プロビジョナル", value: "provisional" },
        ];
      } else if (
        [
          "fixed_prosthesis.prosthesis.face_exfoliation.reproduction",
          "fixed_prosthesis.prosthesis.face_exfoliation.fix",
          "fixed_prosthesis.prosthesis.face_discoloration.reproduction",
          "fixed_prosthesis.prosthesis.face_discoloration.fix",
        ].includes(parentKey)
      ) {
        return [
          { text: "選択してください", value: "" },
          { text: "ハイブリッドセラミック", value: "hybrid_ceramic" },
          { text: "ポーセレン", value: "porcelain" },
        ];
      } else if (
        [
          "removable_prosthesis.magnet_denture.magnet_desorption.reproduction",
          "removable_prosthesis.magnet_denture.magnet_desorption.fix",
          "removable_prosthesis.magnet_denture.magnet_degradation.reproduction",
          "removable_prosthesis.magnet_denture.magnet_degradation.fix",
        ].includes(parentKey)
      ) {
        return [
          { text: "選択してください", value: "" },
          { text: "マグネットの交換", value: "magnet_replacement" },
          { text: "義歯修理", value: "denture_repair" },
          { text: "再製作", value: "reproduction" },
        ];
      } else if (
        [
          "removable_prosthesis.o_ring_denture.abutment_side_fracture.reproduction",
          "removable_prosthesis.o_ring_denture.abutment_side_fracture.fix",
          "removable_prosthesis.o_ring_denture.o_ring_degradation.reproduction",
          "removable_prosthesis.o_ring_denture.o_ring_degradation.fix",
        ].includes(parentKey)
      ) {
        return [
          { text: "選択してください", value: "" },
          { text: "オーリングの交換", value: "o_ring_replacement" },
          { text: "義歯修理", value: "denture_repair" },
          { text: "再製作", value: "reproduction" },
        ];
      } else if (
        [
          "removable_prosthesis.bar_attachment_denture.clip_loose.reproduction",
          "removable_prosthesis.bar_attachment_denture.clip_loose.fix",
          "removable_prosthesis.bar_attachment_denture.clip_fracture.reproduction",
          "removable_prosthesis.bar_attachment_denture.clip_fracture.fix",
          "removable_prosthesis.bar_attachment_denture.bar_fracture.reproduction",
          "removable_prosthesis.bar_attachment_denture.bar_fracture.fix",
        ].includes(parentKey)
      ) {
        return [
          { text: "選択してください", value: "" },
          {
            text: "バーアタッチメントの交換",
            value: "bar_atachement_replacement",
          },
          { text: "義歯修理", value: "denture_repair" },
          { text: "再製作", value: "reproduction" },
        ];
      }
    }
  } else if (retreatmentType === "implant_body") {
    if (moduleName === "part_1") {
      return [
        { text: "選択してください", value: "" },
        { text: "インプラント体", value: "implant_body" },
        { text: "アバットメント", value: "abutment" },
      ];
    } else if (moduleName === "part_2") {
      if (parentKey === "implant_body" || !parentKey) {
        return [
          { text: "選択してください", value: "" },
          { text: "再埋入", value: "reembedding" },
          { text: "その他", value: "other" },
        ];
      } else if (parentKey === "abutment") {
        return [
          { text: "選択してください", value: "" },
          { text: "その他", value: "other" },
        ];
      }
    } else if (moduleName === "cause") {
      if (parentKey === "implant_body.reembedding") {
        return [
          { text: "選択してください", value: "" },
          { text: "脱落", value: "fall_away" },
          { text: "その他", value: "other" },
        ];
      } else if (parentKey === "abutment.other") {
        return [
          { text: "選択してください", value: "" },
          {
            text: "アバットメントネック部の破折",
            value: "abutment_neck_fracture",
          },
          { text: "アバットメントの緩み", value: "abutment_loose" },
          { text: "その他", value: "other" },
        ];
      }
    } else if (moduleName === "operation") {
      if (parentKey === "implant_body.reembedding.fall_away") {
        return [
          { text: "選択してください", value: "" },
          { text: "インプラント体", value: "implant_body" },
          { text: "直接入力", value: "__input" },
        ];
      }
      if (parentKey === "abutment.other.abutment_neck_fracture") {
        return [
          { text: "選択してください", value: "" },
          { text: "破折部位の研磨", value: "polishing" },
          { text: "削合による形態修正", value: "recountering" },
        ];
      } else if (parentKey === "abutment.other.abutment_loose") {
        return [
          { text: "選択してください", value: "" },
          { text: "アバットメント交換", value: "abutment_replacement" },
          { text: "アバットメント調整", value: "abutment_adjustment" },
        ];
      }
    }
  } else if (retreatmentType === "beauty") {
    if (moduleName === "part") {
      return [
        { text: "選択してください", value: "" },
        { text: "補綴装置", value: "prosthesis" },
        { text: "その他", value: "other" },
      ];
    } else if (moduleName === "cause") {
      if (parentKey === "prosthesis") {
        return [
          { text: "選択してください", value: "" },
          {
            text:
              "クラウン（メタル・ジルコニア・アルミナ・ハイブリッド・PFZ・PFM等）の破折",
            value: "crown_flucture",
          },
          { text: "前装材料の破折", value: "face_fructure" },
          { text: "前装材料の剥離", value: "face_exfoliation" },
          { text: "インレーの破折", value: "inlay_fructure" },
          { text: "二次カリエス", value: "secondary_caries" },
          { text: "根管トラブル（Pel,Pur）等", value: "root_canal_trouble" },
          { text: "歯根破折", value: "root_fructure" },
        ];
      }
    } else if (moduleName === "operation") {
      if (parentKey === "prosthesis.crown_flucture") {
        return [
          { text: "選択してください", value: "" },
          { text: "補綴物再製作", value: "prosthesis_reproduction" },
          { text: "再印象", value: "reimpression" },
          { text: "バイト", value: "bite" },
        ];
      } else if (parentKey === "prosthesis.face_fructure") {
        return [
          { text: "選択してください", value: "" },
          { text: "破折部位の研磨", value: "polishing" },
          { text: "削合による形態修正", value: "recountering" },
          { text: "再製作", value: "reproduction" },
          { text: "修理", value: "fix" },
        ];
      } else if (parentKey === "prosthesis.inlay_fructure") {
        return [
          { text: "選択してください", value: "" },
          { text: "上部構造再製作", value: "upper_structure_reproduction" },
          { text: "再印象", value: "reimpression" },
          { text: "バイト", value: "bite" },
        ];
      } else if (
        [
          "prosthesis.secondary_caries",
          "prosthesis.root_canal_trouble",
          "prosthesis.root_fructure",
        ].includes(parentKey)
      ) {
        return [
          { text: "選択してください", value: "" },
          { text: "上部構造再製作", value: "upper_structure_reproduction" },
          { text: "再印象", value: "reimpression" },
          { text: "バイト", value: "bite" },
          { text: "インプラント体埋入", value: "implant_reembedding" },
        ];
      } else if (
        [
          "prosthesis.face_exfoliation",
          "prosthesis.face_discoloration",
        ].includes(parentKey)
      ) {
        return [
          { text: "選択してください", value: "" },
          { text: "再製作", value: "reproduction" },
          { text: "修理", value: "fix" },
        ];
      }
    } else if (moduleName === "material") {
      if (
        [
          "prosthesis.crown_flucture.prosthesis_reproduction",
          "prosthesis.inlay_fructure.upper_structure_reproduction",
          "prosthesis.secondary_caries.upper_structure_reproduction",
          "prosthesis.root_canal_trouble.upper_structure_reproduction",
          "prosthesis.root_fructure.upper_structure_reproduction",
        ].includes(parentKey)
      ) {
        return [
          { text: "選択してください", value: "" },
          { text: "オールジルコニア", value: "all_zirconia" },
          { text: "メタル", value: "metal" },
          { text: "ハイブリッドセラミック", value: "hybrid" },
          { text: "PFM（メタルボンド）", value: "pfm" },
          { text: "PFZ（ジルコニアボンド）", value: "pfz" },
          { text: "アルミナ", value: "alumina" },
          { text: "ガラスセラミック（e-max等）", value: "e-max" },
        ];
      } else if (
        [
          "prosthesis.face_fructure.reproduction",
          "prosthesis.face_fructure.fix",
        ].includes(parentKey)
      ) {
        return [
          { text: "選択してください", value: "" },
          { text: "再製作用印象材", value: "reimpression_metal" },
          { text: "プロビジョナル", value: "provisional" },
        ];
      } else if (
        [
          "prosthesis.face_exfoliation.reproduction",
          "prosthesis.face_exfoliation.fix",
          "prosthesis.face_discoloration.reproduction",
          "prosthesis.face_discoloration.fix",
        ].includes(parentKey)
      ) {
        return [
          { text: "選択してください", value: "" },
          { text: "ハイブリッドセラミック", value: "hybrid_ceramic" },
          { text: "ポーセレン", value: "porcelain" },
        ];
      }
    }
  }
  throw new Error(`Unexpected parentKey "${parentKey}"`);
}

// フォームのHTMLを取得する
function getForm(retreatmentType, init) {
  let partCells;
  if (
    retreatmentType === "implant_upper" ||
    retreatmentType == "implant_body"
  ) {
    let options_part_1 = getOptionsForSelect(retreatmentType, "part_1").map(
      (v) => `<option value="${v.value}">${v.text}</option>`
    );
    let options_part_2 = getOptionsForSelect(retreatmentType, "part_2").map(
      (v) => `<option value="${v.value}">${v.text}</option>`
    );
    partCells = `
    <tr height="51px">
      <th>治療部位1</th>
      <td data-expense-module-container="part_1" colspan="3">
        <select data-expense-module="part_1" class="form-select">
          ${options_part_1}
        </select>
      </td>
    </tr>
    <tr height="51px">
      <th>治療部位2</th>
      <td data-expense-module-container="part_2" colspan="3">
      </td>
    </tr>
    `;
  } else {
    let options_part = getOptionsForSelect(retreatmentType, "part").map(
      (v) => `<option value="${v.value}">${v.text}</option>`
    );
    partCells = `
    <tr height="51px">
      <th>治療部位</th>
      <td colspan="3" data-expense-module-container="part" colspan="3">
        <select data-expense-module="part" class="form-select">
          ${options_part}
        </select>
      </td>
    </tr>
    `;
  }

  return `
  <div data-expense-form-container data-expense-data="{}" style="margin: 1em 0 1em 0;">
  ${
    // 最初のフォームだけは削除ボタンをレンダリングしない
    !init
      ? `
    <div style="text-align: right;">
      <button type="button" style="margin: .5em;" data-expense-control="delete" class="btn-type-b">削除</button>
    </div>
    `
      : ""
  }
    <table class="table-type-b" style="table-layout: fixed;">
      <tbody>
        ${partCells}
        <tr height="51px">
          <th width="15%">治療原因</th>
          <td data-expense-module-container="cause" colspan="3">
          </td>
        </tr>
        <tr height="51px">
          <th width="15%">詳細治療項目</th>
          <td data-expense-module-container="operation" colspan="3"></td>
        </tr>
        <tr height="51px">
          <th width="15%">必要な材料</th>
          <td data-expense-module-container="material" colspan="3"></td>
        </tr>
        <tr height="51px">
          <th>歯科医師技術料</th>
          <td data-expense-module-container="dentist_price">
            <input type="text" class="input-txt" data-expense-dentist-price disabled="true" value="0" data-validate='["required", "numeric"]'>
          </td>
          <th width="15%">材料費</th>
          <td data-expense-module-container="material_price">
            <input type="text" class="input-txt" data-expense-material-price disabled="true" value="0" data-validate='["required", "numeric"]'>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  ${
    // initの場合のみ追加ボタンを生成
    init
      ? `<button type="button" data-expense-control="add" class="btn-type-b">＋入力欄を追加</button>`
      : ""
  }
  `;
}

// 親のmoduleKeyからセレクトフォームを生成
function getSelectModule(retreatmentType, moduleName, parentKey) {
  let options = getOptionsForSelect(retreatmentType, moduleName, parentKey);
  return `
    <select data-expense-module="${moduleName}" data-expense-val='""' class="form-select" data-validate='["required"]'>
      ${options.map((v) => `<option value="${v.value}">${v.text}</option>`)}
    </select>
  `;
}

// 直接入力も可能なセレクトフォームを生成（インプラント体 > 再埋入 > 脱落のみ）
function getSelectWithTextModule() {
  let options = getOptionsForSelect(
    "implant_body",
    "operation",
    "implant_body.reembedding.fall_away"
  );
  return `
  <span data-expense-module="operation" data-expense-val='""'>
    <select data-expense-select-text class="form-select" data-validate='["required"]'>
      ${options.map((v) => `<option value="${v.value}">${v.text}</option>`)}
    </select>
    <input type="text" data-expense-select-text class="input-txt app-input-txt" style="display: none;" data-validate='["required"]'>
  </span>
  `;
}

// テキストフォームを生成
function getTextModule(moduleName) {
  return `<input type="text" data-expense-module="${moduleName}" data-expense-val='""' class="input-txt" data-validate='["required"]'>`;
}

// 「特になし」のラベルフォームを生成
function getNoneLabelModule() {
  return `
  <span data-expense-module="material" data-expense-val='"none"'>
    特になし（クリニックレベル）
  </span>
  `;
}

// 上部構造再製作のチェックボックスを取得
function getCheckBoxes() {
  let choices = [
    { text: "オールジルコニア", value: "all_zirconia" },
    { text: "メタル", value: "metal" },
    { text: "ハイブリッドセラミック", value: "hybrid" },
    { text: "PFM（メタルボンド）", value: "pfm" },
    { text: "PFZ（ジルコニアボンド）", value: "pfz" },
    { text: "アルミナ", value: "alumina" },
  ];
  let checkboxes = choices.map(
    (v) => `
    <label>
      <input type="checkbox" value="${v.value}" data-expense-checkbox data-validate='["required_group"]' data-required-group="expense_materials">
      ${v.text}
    </label>
  `
  );
  return `
  <span data-expense-checkbox-container data-expense-val="[]">
    ${checkboxes.join("")}
  </span>
  `;
}

// モジュール自身のドット区切りのキーを取得（子モジュール生成の際に、parentの値として利用する）
function getModuleKey(elem) {
  let moduleArray = [];
  let moduleName = $(elem).attr("data-expense-module");
  elem
    .parents("[data-expense-form-container]")
    .find("[data-expense-module]")
    .each(function (index, elem) {
      moduleArray.push(JSON.parse($(elem).attr("data-expense-val")));
      if (moduleName === $(elem).attr("data-expense-module")) {
        return false;
      }
    });
  return moduleArray.join(".");
}

// モジュールを操作変更したときの処理
function handleChangeModule(elem) {
  // セレクトボックス以外の場合は何もしない
  if (elem.prop("tagName") !== "SELECT") {
    return;
  }

  // 治療費と材料費のモジュールを取得
  let priceModules = elem
    .parents("[data-expense-form-container]")
    .find("[data-expense-dentist-price],[data-expense-material-price]");

  // 素材の場合でかつ値がある場合、治療費、材料費を有効化して終了
  let moduleName = elem.attr("data-expense-module");
  if (moduleName === "material" && elem.val()) {
    priceModules.prop("disabled", false);
    return;
  }

  // 治療費と材料費のモジュールを無効化して値を0にする
  priceModules.prop("disabled", true);
  priceModules.val(0);
  validator.clearError(priceModules);

  // 素材の場合はここまで
  if (moduleName === "material") {
    return;
  }

  // 次モジュールと削除対象モジュールを取得
  let nextModuleName, moduleRemoved;
  if (moduleName === "part_1") {
    nextModuleName = "part_2";
    moduleRemoved = ["cause", "operation", "material"];
  } else if (["part_2", "part"].includes(moduleName)) {
    nextModuleName = "cause";
    moduleRemoved = ["operation", "material"];
  } else if (moduleName === "cause") {
    nextModuleName = "operation";
    moduleRemoved = ["material"];
  } else if (moduleName === "operation") {
    nextModuleName = "material";
    moduleRemoved = [];
  }

  // 次のモジュールのコンテナ（テーブルセル）取得
  let targetModuleContainer = elem
    .parents("[data-expense-form-container]")
    .find(`[data-expense-module-container="${nextModuleName}"]`);

  // まず次モジュールと削除対象モジュールを削除
  targetModuleContainer
    .find(
      "[data-expense-module],[data-expense-checkbox-container],[data-validate-msg]"
    )
    .remove();
  let formContainer = elem.parents("[data-expense-form-container]");
  formContainer.find("[data-expense-module]").each(function (index, elem) {
    if (moduleRemoved.includes($(elem).attr("data-expense-module"))) {
      $(elem)
        .closest("[data-expense-module-container]")
        .find("[data-validate-msg]")
        .remove();
      $(elem).remove();
    }
  });
  if (moduleRemoved.includes("material")) {
    formContainer.find("[data-expense-checkbox-container]").remove();
  }

  // 値がある場合のみモジュール追加処理
  if (elem.val()) {
    let moduleKey = getModuleKey(elem);

    // 部位1 or 部位2「その他」で残りすべてテキストボックスを生成する場合
    if (
      [
        "fixed_prosthesis.other",
        "removable_prosthesis.other",
        "implant_body.other",
        "other",
      ].includes(moduleKey)
    ) {
      ["cause", "operation", "material"].map((moduleName) => {
        let targetModuleContainer = elem
          .parents("[data-expense-form-container]")
          .find(`[data-expense-module-container="${moduleName}"]`);
        let module = getTextModule(moduleName);
        targetModuleContainer.append(module);
      });
      priceModules.prop("disabled", false);
      return;
    }

    // 原因「その他」で残りすべてテキストボックスを生成する場合
    if (
      ["implant_body.reembedding.other", "abutment.other.other"].includes(
        moduleKey
      )
    ) {
      ["operation", "material"].map((moduleName) => {
        let targetModuleContainer = elem
          .parents("[data-expense-form-container]")
          .find(`[data-expense-module-container="${moduleName}"]`);
        let module = getTextModule(moduleName);
        targetModuleContainer.append(module);
      });
      priceModules.prop("disabled", false);
      return;
    }

    // チェックボックスを生成する場合（上部構造再製作のみ）
    if (
      moduleKey ===
      "fixed_prosthesis.prosthesis.prosthesis_fructure.upper_structure_reproduction"
    ) {
      let module = getCheckBoxes();
      targetModuleContainer.append(module);
      priceModules.prop("disabled", false);
      return;
    }

    // 「特になし（クリニックレベル）」を生成する場合
    if (
      [
        "fixed_prosthesis.prosthesis.face_fructure.polishing",
        "fixed_prosthesis.prosthesis.face_fructure.recountering",
        "prosthesis.face_fructure.polishing",
        "prosthesis.face_fructure.recountering",
      ].includes(moduleKey)
    ) {
      let module = getNoneLabelModule();
      targetModuleContainer.append(module);
      priceModules.prop("disabled", false);
      return;
    }

    // テキストボックスを生成する場合
    if (
      [
        "fixed_prosthesis.prosthesis.prosthesis_fructure.reimpression",
        "fixed_prosthesis.prosthesis.prosthesis_fructure.bite",
        "abutment.other.abutment_neck_fracture.polishing",
        "abutment.other.abutment_neck_fracture.recountering",
        "abutment.other.abutment_loose.abutment_replacement",
        "abutment.other.abutment_loose.abutment_adjustment",
        "prosthesis.crown_flucture.reimpression",
        "prosthesis.crown_flucture.bite",
        "prosthesis.inlay_fructure.reimpression",
        "prosthesis.inlay_fructure.bite",
        "prosthesis.secondary_caries.reimpression",
        "prosthesis.secondary_caries.bite",
        "prosthesis.secondary_caries.implant_reembedding",
        "prosthesis.root_canal_trouble.reimpression",
        "prosthesis.root_canal_trouble.bite",
        "prosthesis.root_canal_trouble.implant_reembedding",
        "prosthesis.root_fructure.reimpression",
        "prosthesis.root_fructure.bite",
        "prosthesis.root_fructure.implant_reembedding",
      ].includes(moduleKey)
    ) {
      let module = getTextModule(nextModuleName);
      targetModuleContainer.append(module);
      priceModules.prop("disabled", false);
      return;
    }

    // 直接入力も可能なセレクトボックスを生成する場合
    if (moduleKey === "implant_body.reembedding.fall_away") {
      let module = getSelectWithTextModule();
      targetModuleContainer.append(module);
      return;
    }

    // それ以外（セレクトボックスを生成する場合）
    let retreatmentType = elem
      .parents("[data-expense-retreatment-type]")
      .attr("data-expense-retreatment-type");
    let module = getSelectModule(retreatmentType, nextModuleName, moduleKey);
    targetModuleContainer.append(module);
  }
}

// テキスト付きセレクトボックスを操作変更したときの処理（インプラント体>再埋入>脱落 のみ）
function handleChangeSelectWithText(elem) {
  // セレクトボックスの場合
  if (elem.prop("tagName") === "SELECT") {
    // テキストの表示切替
    if (elem.val() === "__input") {
      elem.next().show();
    } else {
      elem.next().hide();
      elem.next().removeClass("error");
    }
    let nextModuleContainer = elem
      .closest("[data-expense-form-container]")
      .find('[data-expense-module-container="material"]');
    if (!elem.val()) {
      // 入力値がない場合、素材フォームを削除
      nextModuleContainer.find("[data-expense-module]").remove();
    } else {
      // 入力値がある場合、素材のフォームをレンダリング
      let module = getTextModule("material");
      if (!nextModuleContainer.find("[data-expense-module]").length) {
        nextModuleContainer.append(module);
      }
      // 費用フォーム有効化
      elem
        .parents("[data-expense-form-container]")
        .find("[data-expense-dentist-price],[data-expense-material-price]")
        .prop("disabled", false);
    }
  }
  // 親のdata-expense-valを更新
  let val = elem.val() === "__input" ? "" : elem.val();
  elem
    .closest("[data-expense-val]")
    .attr("data-expense-val", JSON.stringify(val));
}

// チェックボックスを操作したときの処理
function handleChangeCheckbox(elem) {
  let container = $(elem).parents("[data-expense-checkbox-container]");
  let expenseData = [];
  container.find("[data-expense-checkbox]").each(function (index, elem) {
    if ($(elem).prop("checked")) {
      expenseData.push($(elem).val());
    }
  });
  container.attr("data-expense-val", JSON.stringify(expenseData));
}

// モジュール自体のdata属性に保持する値を更新
function updateModuleValue() {
  $(
    "[data-expense-module],[data-expense-dentist-price],[data-expense-material-price]"
  ).each(function (index, elem) {
    if ($(elem).prop("tagName") !== "SPAN") {
      $(elem).attr("data-expense-val", JSON.stringify($(elem).val()));
    }
  });
}

// フォームのdata属性にセットするJSONを更新
function updateExpenseData() {
  $("[data-expense-form-container]").each(function (index, elem) {
    let expenseData = JSON.parse($(elem).attr("data-expense-data"));
    $(elem)
      .find("[data-expense-module]")
      .each(function (index, elem) {
        if ($(elem).prop("type") === "checkbox") {
          let moduleName = $(elem).attr("data-expense-module");
          expenseData[moduleName].push($(elem).val());
        } else {
          expenseData[$(elem).attr("data-expense-module")] = JSON.parse(
            $(elem).attr("data-expense-val")
          );
        }
      });
    $(elem)
      .find("[data-expense-checkbox-container]")
      .each(function (index, elem) {
        expenseData["material"] = $(elem).attr("data-expense-val");
      });
    $(elem)
      .find("[data-expense-dentist-price]")
      .each(function (index, elem) {
        expenseData["dentist_price"] = convertPrice($(elem).val());
      });
    $(elem)
      .find("[data-expense-material-price]")
      .each(function (index, elem) {
        expenseData["material_price"] = convertPrice($(elem).val());
      });
    $(elem).attr("data-expense-data", JSON.stringify(expenseData));
  });
}

// 合計金額を更新
function calculatePrice() {
  $("[data-expense-retreatment-type]").each(function (index, elem) {
    let retreatmentType = $(elem).attr("data-expense-retreatment-type");
    let dataArr = getDataArray(retreatmentType);
    let materialPrice = 0;
    let dentistPrice = 0;
    dataArr.map((v) => {
      if (typeof v.material_price === "number") {
        materialPrice += v.material_price;
      }
    });
    dataArr.map((v) => {
      if (typeof v.dentist_price === "number") {
        dentistPrice += v.dentist_price;
      }
    });
    $(
      `[data-expense-sum-retreatment-type="${retreatmentType}"][data-expense-sum="dentist"]`
    ).text(dentistPrice.toLocaleString());
    $(
      `[data-expense-sum-retreatment-type="${retreatmentType}"][data-expense-sum="material"]`
    ).text(materialPrice.toLocaleString());

    if (["implant_upper", "implant_body"].includes(retreatmentType)) {
      let sum = 0;
      if (retreatmentType === "implant_upper") {
        $(
          `[data-expense-sum-retreatment-type="${retreatmentType}"][data-expense-sum="subtotal"]`
        ).text((materialPrice + dentistPrice).toLocaleString());

        let burdenPrice = convertPrice($("[data-expense-burden]").val());
        if (typeof burdenPrice === "number") {
          sum = materialPrice + dentistPrice - burdenPrice;
        }
      } else if (retreatmentType === "implant_body") {
        sum = materialPrice + dentistPrice;
      }
      $(
        `[data-expense-sum-retreatment-type="${retreatmentType}"][data-expense-sum="sum"]`
      ).text(sum.toLocaleString());
    } else {
      let sum = materialPrice + dentistPrice;
      $(
        `[data-expense-sum-retreatment-type="${retreatmentType}"][data-expense-sum="sum"]`
      ).text(sum.toLocaleString());
    }
  });
  // 最終合計金額の計算
  let final = 0;
  $('[data-expense-sum="sum"]').each(function (index, elem) {
    final += getPriceValueFromText(
      $(elem).attr("data-expense-sum-retreatment-type"),
      "sum"
    );
  });
  $("[data-expense-sum-final]").text(final.toLocaleString());
}

// 金額のテキストデータから値を取得する
function getPriceValueFromText(retreatmentType, priceType) {
  let priceText;
  priceText = $(
    `[data-expense-sum="${priceType}"][data-expense-sum-retreatment-type="${retreatmentType}"]`
  ).text();
  return convertPrice(priceText);
}

// テキストを金額に変換
function convertPrice(value) {
  return value
    ? /^[1-9](\d|,)*$/.test(value)
      ? parseInt(value.replace(/,/g, ""))
      : 0
    : 0;
}

// データをロードする
function loadData() {
  let expenseData = getPostData();
  console.log(expenseData);
  $(`[data-expense-retreatment-type]`).each(function (index, elem) {
    let retreatmentType = $(elem).attr("data-expense-retreatment-type");
    if (expenseData[retreatmentType]) {
      // データがまだ無い場合は初期フォームを生成
      if (expenseData[retreatmentType]["datas"].length === 0) {
        let form = getForm(retreatmentType, { init: true });
        $(`[data-expense-retreatment-type="${retreatmentType}"]`).append(form);
        updateModuleValue();
      } else {
        expenseData[retreatmentType]["datas"].map((data, i) => {
          // まず空のフォームをappend
          if (i === 0) {
            let form = getForm(retreatmentType, true);
            $(`[data-expense-retreatment-type="${retreatmentType}"]`).append(
              form
            );
          } else {
            let addButton = $(
              `[data-expense-retreatment-type="${retreatmentType}"]`
            ).find($('[data-expense-control="add"]'));
            addForm(addButton);
          }

          let appendedForm = $(
            `[data-expense-retreatment-type="${retreatmentType}"]`
          )
            .find($('[data-expense-control="add"]'))
            .prev();

          // モジュール毎に値を代入し、changeイベントを発火する
          let moduleNames = ["implant_upper", "implant_body"].includes(
            retreatmentType
          )
            ? ["part_1", "part_2", "cause", "operation", "material"]
            : ["part", "cause", "operation", "material"];
          moduleNames.map((moduleName) => {
            let module = appendedForm.find(
              `[data-expense-module="${moduleName}"]`
            );

            module.val(data[moduleName]);
            updateModuleValue();
            // テキストボックス付きセレクトボックス限定読み込み
            if (module.find("[data-expense-select-text]").length) {
              let select = module.find("select[data-expense-select-text]");
              let textbox = module.find("input[data-expense-select-text]");
              if (data[moduleName] === "implant_body") {
                select.val("implant_body");
                handleChangeSelectWithText(select);
              } else if (data[moduleName]) {
                select.val("__input");
                handleChangeSelectWithText(select);
                textbox.val(data[moduleName]);
                handleChangeSelectWithText(textbox);
              }
            } else {
              handleChangeModule(module);
            }
            setEvents();
            updateExpenseData();

            // チェックボックス専用処理
            let materialData;
            try {
              materialData = JSON.parse(data.material);
            } catch (e) {}
            if (moduleName === "material" && materialData instanceof Array) {
              let module = appendedForm.find(
                "[data-expense-checkbox-container]"
              );
              let materials = JSON.parse(data.material);
              materials.map((v) => {
                module
                  .find("[data-expense-checkbox]")
                  .each(function (index, elem) {
                    if ($(elem).val() === v) {
                      $(elem).prop("checked", true);
                      handleChangeCheckbox($(elem));
                      updateExpenseData();
                      return false;
                    }
                  });
              });
            }
          });

          // 費用を代入してChangeイベントを発火
          appendedForm
            .find("[data-expense-dentist-price]")
            .val(data.dentist_price);
          appendedForm
            .find("[data-expense-material-price]")
            .val(data.material_price);
          updateModuleValue();
          updateExpenseData();
          calculatePrice();
        });
      }

      if (retreatmentType === "implant_upper") {
        $("[data-expense-burden]").val(
          expenseData[retreatmentType]["sum"]["patient_burden_price"]
        );
        calculatePrice();
      }
    }
  });
}

// データJSONの配列を取得する
function getDataArray(retreatmentType, draft) {
  let dataArr = [];
  $(`[data-expense-retreatment-type="${retreatmentType}"]`)
    .find("[data-expense-data]")
    .each(function (index, elem) {
      let data = JSON.parse($(elem).attr("data-expense-data"));
      dataArr.push(data);
    });
  return dataArr;
}

// POST用JSONデータをhidden_fieldにセットする
function setPostData() {
  let postData = {};
  $("[data-expense-retreatment-type]").each(function (index, elem) {
    let retreatmentType = $(elem).attr("data-expense-retreatment-type");
    postData[retreatmentType] = { sum: {}, datas: [] };
    let dataArr = getDataArray(retreatmentType);
    postData[retreatmentType]["datas"] = dataArr;

    let materialPrice = 0;
    let dentistPrice = 0;
    dataArr.map((v) => {
      dentistPrice += v.dentist_price;
      materialPrice += v.material_price;
    });
    postData[retreatmentType]["sum"]["dentist_price"] = dentistPrice;
    postData[retreatmentType]["sum"]["material_price"] = materialPrice;

    if (retreatmentType === "implant_upper") {
      postData[retreatmentType]["sum"]["patient_burden_price"] = convertPrice(
        $("[data-expense-burden]").val()
      );
    }
  });
  $("[data-expense-post-data]").val(JSON.stringify(postData));
}

// POST用データを取得する
function getPostData() {
  return JSON.parse($("[data-expense-post-data]").val());
}

// 追加ボタンを引数に取り、フォームを生成する
function addForm(elem) {
  let retreatmentType = elem
    .parents("[data-expense-retreatment-type]")
    .attr("data-expense-retreatment-type");
  let form = getForm(retreatmentType);
  elem.before(form);
  updateModuleValue();
  setEvents();
}

// 削除ボタンを引数に取り、フォームを削除する
function removeForm(elem) {
  elem.parents("[data-expense-form-container]").remove();
  calculatePrice();
}

// コンポーネントのイベントを登録
function setEvents() {
  $('[data-expense-control="add"]').off("click");
  $('[data-expense-control="add"]').on("click", function (e) {
    addForm($(this));
    setEvents();
    updateExpenseData();
  });
  $('[data-expense-control="delete"]').off("click");
  $('[data-expense-control="delete"]').on("click", function (e) {
    removeForm($(this));
  });
  $("[data-expense-module]").off("change");
  $("[data-expense-module]").on("change", function (e) {
    // セレクトボックスかテキストフィールド以外の場合は何もしない
    if (
      $(this).prop("tagName") !== "SELECT" &&
      $(this).prop("type") !== "text"
    ) {
      return;
    }
    updateModuleValue();
    handleChangeModule($(this));
    setEvents();
    updateExpenseData();
    calculatePrice();
  });
  $("[data-expense-dentist-price],[data-expense-material-price]").off("change");
  $("[data-expense-dentist-price],[data-expense-material-price]").on(
    "change",
    function (e) {
      updateModuleValue();
      updateExpenseData();
      calculatePrice();
    }
  );
  $("[data-expense-burden]").off("change");
  $("[data-expense-burden]").on("change", function (e) {
    calculatePrice();
  });
  $("[data-expense-checkbox]").off("change");
  $("[data-expense-checkbox]").on("change", function (e) {
    handleChangeCheckbox($(this));
    updateExpenseData();
  });
  $("[data-expense-select-text]").off("change");
  $("[data-expense-select-text]").on("change", function (e) {
    handleChangeSelectWithText($(this));
    updateExpenseData();
    setEvents();
  });
  validator.init();
}

module.exports = {
  init: function () {
    loadData();
    setEvents();
    updateExpenseData();
    calculatePrice();
    validator.validateAll();
  },
  addForm: addForm,
  removeForm: removeForm,
  setPostData: setPostData,
};
