const validator = require("./validator");

(function () {
  var moment = require("moment");

  // 汎用メソッド定義ここから
  const utils = {
    getModelName: function () {
      return $("form").data("model-name");
    },
    getCurrentFormData: function () {
      return $("form").serialize();
    },
    // 破損日時の値をhidden fieldから取得してフォームに表示
    loadDamagedAt: function () {
      if ($("[data-input-damaged-at]").val()) {
        let damaged_at = moment(new Date($("[data-input-damaged-at]").val()));
        $("#damaged_at_date").val(damaged_at.format("YYYY-MM-DD"));
        if (damaged_at.hour() < 12) {
          $("#damaged_at_ampm_am").prop("checked", true);
          $("#damaged_at_hour").val(damaged_at.hour());
        } else {
          $("#damaged_at_ampm_pm").prop("checked", true);
          $("#damaged_at_hour").val(damaged_at.hour() - 12);
        }
      }
    },
    // フォームを初期化する
    initForms: function () {
      this.loadDamagedAt();
      $("[data-other-trigger]").each((i, e) => {
        this.toggleOtherForm($(e));
      });
      $("[data-select-doctor]").each((i, e) => {
        this.toggleDoctorOtherForm($(e));
      });
      $("[data-damaged-tooth-check]").each((i, e) => {
        this.toggleRetreatedToothCondition($(e));
      });
    },
    // 日付、午前、午後、◯時の入力値をPOST用に変換してhidden fieldにセット
    setDamagedAt: function () {
      let date = $("#damaged_at_date").val();
      let hour = parseInt($("#damaged_at_hour").val());
      let ampm = $('input[name="damaged_at_ampm"]:checked').val();
      hour = ampm === "pm" ? hour + 12 : hour;
      let damaged_at = moment(new Date(`${date} ${hour}:00:00`));
      $("[data-input-damaged-at]").val(damaged_at.format());
    },
    // 医者リストのli要素を取得
    getDoctorListHtmlElement: function (doctor_name = "") {
      let doctors_all = $("[data-doctor-list]").data("doctors-all");
      const getOptionTags = function (doctors) {
        let options = [];
        options.push('<option value="">選択してください</option>');
        for (let i in doctors) {
          // 既に選択済みの医師は選択肢に含めない
          if (
            !$("[data-doctor-hidden]")
              .map(function (i, e) {
                return $(e).val() === doctors[i];
              })
              .toArray()
              .includes(true)
          ) {
            options.push(
              `<option value="${doctors[i]}">${doctors[i]}</option>`
            );
          }
        }
        options.push('<option value="other">その他</option>');
        return options;
      };

      return `
      <li data-doctor-item data-doctor="${doctor_name}" style="margin-top: 1em;">
        <select class="form-select" style="margin-right: 12px;" data-select-doctor>
          ${getOptionTags(doctors_all).join()}
        </select>
        <input type="text" style="display: none; width: 20%;" class="input-txt app-input-txt" placeholder="医師名を入力してください" data-doctor-other>
        <a class="btn-type-s" data-remove-doctor href="javascript:void(0)">削除</a>
      </li>
      `;
    },
    // 「医師」フォームのその他テキスト表示非表示切り替え
    toggleDoctorOtherForm: function (element) {
      element.next("input").toggle(element.val() === "other");
      if (element.val() === "other") {
        element.next("input").val(element.parent("li").data("doctor"));
      }
    },
    // 医者リストのhidden field要素を取得
    getDoctorHiddenFieldHtmlElement: function (doctor_name) {
      let model_name = utils.getModelName();
      return `
      <input multiple="multiple" value="${doctor_name}" type="hidden" name="${model_name}[doctors_ary][]" data-doctor-hidden>
      `;
    },
    // status_kbnを変更する
    setStatusKbn: function (status_kbn) {
      $("[data-status-kbn]").val(status_kbn);
    },
    // 引数の element で 'その他' が選択されているかに応じて、同じ data-group に属するその他テキストフォームを表示・非表示
    toggleOtherForm: function (element) {
      let form_group = element.data("group");
      let toggle;
      if (element.prop("tagName") === "SELECT") {
        if (form_group.match(/^prosthesis_type_kbn/)) {
          toggle =
            $(
              `[data-group="${form_group}"][data-other-trigger="true"]`
            ).val() === "bridge";
        } else {
          toggle =
            $(
              `[data-group="${form_group}"][data-other-trigger="true"]`
            ).val() === "other";
        }
      } else if (element.prop("type") === "radio") {
        if (form_group.match(/^smoking_kbn/)) {
          toggle =
            $(
              `[data-group="${form_group}"][data-other-trigger="true"]:checked`
            ).val() === "smoke";
        } else if (form_group.match(/^abutment_1_kbn_/)) {
          toggle =
            $(
              `[data-group="${form_group}"][data-other-trigger="true"]:checked`
            ).val() === "custom";
        } else {
          toggle =
            $(
              `[data-group="${form_group}"][data-other-trigger="true"]:checked`
            ).val() === "other";
        }
      }
      let target = $(`[data-group="${form_group}"][data-other-form="true"]`);
      target.toggle(toggle);
      if (!toggle) {
        validator.clearError(target);
        if (
          form_group.match(/^smoking_kbn/) ||
          form_group.match(/^prosthesis_type_kbn/)
        ) {
          target = target.find("input").val("");
        } else {
          target.val("");
        }
      }
    },
    // 現在セットされている医師名一覧
    getDoctors: function () {
      let model_name = utils.getModelName();
      if ($(`input[name="${model_name}[doctors_ary][]"]`).length > 0) {
        return $(`input[name="${model_name}[doctors_ary][]"]`)
          .map((i, e) => $(e).val())
          .toArray();
      } else {
        return [];
      }
    },
    // 「再治療対象の状況」フォームの表示非表示を切り替え
    toggleRetreatedToothCondition: function (element) {
      let toothId = element.data("damaged-tooth-id");
      let checked = element.prop("checked");
      $(`[data-damaged-tooth-destroy][data-damaged-tooth-id="${toothId}"]`).val(
        !checked
      );
      $(`[data-damaged-tooth-updates][data-damaged-tooth-id="${toothId}"]`).val(
        checked
      );
      $(`span[data-damaged-tooth-id="${toothId}"]`).toggle(checked);
    },
  };
  // 汎用メソッド定義ここまで

  // フォームの状態を取得（beforeunload用）
  let originalForm;

  // クラス定義ここから
  Gdt.RetreatmentScheduleFormView = Marionette.View.extend({
    initialize: function () {
      utils.initForms();
      originalForm = utils.getCurrentFormData();
      // フォームが変更されている場合、遷移前に警告を表示
      // ※ Chrome, Firefoxではデフォルトのメッセージしか表示されない
      if ($("[data-page]").val() === "schedule") {
        $(window).on("beforeunload", function (e) {
          if (originalForm !== utils.getCurrentFormData()) {
            return "この操作を行うと、行った変更が破棄されます";
          }
        });
      }
      validator.init();
      // エラーがある場合、全要素のバリデーションを実行してエラーを表示
      if ($("[data-error]").length) {
        validator.validateAll();
      }
    },
    ui: {
      checkDamagedTooth: "[data-damaged-tooth-check]",
      otherTrigger: '[data-other-trigger="true"]',
      btnAddDoctor: "#btn-add-doctor",
      btnRemoveDoctor: "[data-remove-doctor]",
      selectDoctorsChoice: "[data-select-doctor]",
      textDoctorOther: "[data-doctor-other]",
      ulDoctors: "[data-doctor-list]",
      btnSubmit: "#btn-submit",
      btnSaveDraft: "#btn-save-draft",
      btnDelete: "#btn-delete",
      form: "form",
    },
    // イベントの定義
    events: {
      "change @ui.checkDamagedTooth": "handleCheckDamagedTooth",
      "change @ui.otherTrigger": "handleChangeOtherTrigger",
      "change @ui.radioSmokingKbn": "handleChangeRadioSmokingKbn",
      "click @ui.btnAddDoctor": "handleClickBtnAddDoctor",
      "change @ui.selectDoctorsChoice": "handleChangeSelectDoctorsChoice",
      "change @ui.textDoctorOther": "handleChangeTextDoctorOther",
      "click @ui.btnRemoveDoctor": "handleClickBtnRemoveDoctor",
      "click @ui.btnSubmit": "handleClicBtnSubmit",
      "click @ui.btnSaveDraft": "handleClickBtnSaveDraft",
    },
    handleCheckDamagedTooth: function (event) {
      utils.toggleRetreatedToothCondition($(event.currentTarget));
    },
    handleChangeOtherTrigger: function (event) {
      utils.toggleOtherForm($(event.currentTarget));
    },
    // 医師関連のコントローラ
    // 医師追加
    handleClickBtnAddDoctor: function () {
      // 医師フォーム追加
      this.ui.ulDoctors.append(utils.getDoctorListHtmlElement());
    },
    // 医師選択
    handleChangeSelectDoctorsChoice: function (event) {
      let select = $(event.currentTarget);
      let doctor_name = select.val();
      select.siblings("[data-doctor-hidden]").remove();
      // その他フォーム表示非表示
      utils.toggleDoctorOtherForm(select);
      // hidden input要素追加
      if (doctor_name !== "other") {
        select.parent("li").data("doctor", doctor_name);
        select.siblings("[data-doctor-hidden]").remove();
        if (doctor_name) {
          select
            .parent("li")
            .append(utils.getDoctorHiddenFieldHtmlElement(doctor_name));
        }
      } else {
        select.parent("li").data("doctor", null);
      }
    },
    // 医師 その他入力
    handleChangeTextDoctorOther: function (event) {
      let doctor_name = $(event.currentTarget).val();
      console.log("hogehoge");
      $(event.currentTarget).siblings("[data-doctor-hidden]").remove();
      $(event.currentTarget)
        .parent("li")
        .append(utils.getDoctorHiddenFieldHtmlElement(doctor_name));
    },
    // 医師削除
    handleClickBtnRemoveDoctor: function (event) {
      $(event.currentTarget).parent("li[data-doctor-item]").remove();
    },
    // POSTデータ送信
    handleClicBtnSubmit: function () {
      utils.setDamagedAt();
      // status_kbn を進める
      if (
        $("[data-page]").val() === "schedule" &&
        $("[data-status-kbn]").val() === ""
      ) {
        utils.setStatusKbn("schedule_edit");
      } else if (
        $("[data-page]").val() === "schedule_2" &&
        $("[data-status-kbn]").val() === "schedule_edit"
      ) {
        utils.setStatusKbn("schedule_review");
      }
      this.unbindBeforeUnload();
      this.ui.form.submit();
    },
    // 下書き保存
    handleClickBtnSaveDraft: function () {
      utils.setDamagedAt();
      // 下書きでのバリデーションエラー回避のため、予定編集①画面の場合のみ、status_kbn を nil にセット
      if (
        $("[data-page]").val() === "schedule" &&
        $("[data-status-kbn]").val() === "schedule_edit"
      ) {
        utils.setStatusKbn("");
      }
      $('input[name="save_draft"]').val("true");
      this.unbindBeforeUnload();
      this.ui.form.submit();
    },
    // beforeunlodのリスナーをセットする
    bindBeforeUnload: function () {
      $(window).on("beforeunload", function (e) {
        if (originalForm !== utils.getCurrentFormData()) {
          return "この操作を行うと、行った変更が破棄されます";
        }
      });
    },
    // beforeunloadのリスナーを解除する
    unbindBeforeUnload: function () {
      $(window).unbind("beforeunload");
    },
  });
})();
