(function () {
  // ユーティリティ
  Gdt.Util = {
    // 画面上でレコードの行をドラッグして変更する
    sortTable: function () {
      if ($(".app-sortable").length) {
        var SortableTableView = require("common/sortable_table_view").default;
        var sortable = new SortableTableView({
          el: ".app-sortable",
          onRenumber: function (lineNos, oldIndex, newIndex) {
            jQuery.ajax({
              type: "POST",
              url: $(".app-sortable").data("url"),
              data: {
                oldIndex: oldIndex,
                newIndex: newIndex,
                lineNos: lineNos,
              },
              error: function (res) {
                console.log("error");
              },
            });
          },
        });
        sortable.render();
      }
    },

    prefectureHash: {
      北海道: "00201",
      青森県: "00202",
      岩手県: "00203",
      宮城県: "00204",
      秋田県: "00205",
      山形県: "00206",
      福島県: "00207",
      茨城県: "00208",
      栃木県: "00209",
      群馬県: "00210",
      埼玉県: "00211",
      千葉県: "00212",
      東京都: "00213",
      神奈川県: "00214",
      新潟県: "00215",
      富山県: "00216",
      石川県: "00217",
      福井県: "00218",
      山梨県: "00219",
      長野県: "00220",
      岐阜県: "00221",
      静岡県: "00222",
      愛知県: "00223",
      三重県: "00224",
      滋賀県: "00225",
      京都府: "00226",
      大阪府: "00227",
      兵庫県: "00228",
      奈良県: "00229",
      和歌山県: "00230",
      鳥取県: "00231",
      島根県: "00232",
      岡山県: "00233",
      広島県: "00234",
      山口県: "00235",
      徳島県: "00236",
      香川県: "00237",
      愛媛県: "00238",
      高知県: "00239",
      福岡県: "00240",
      佐賀県: "00241",
      長崎県: "00242",
      熊本県: "00243",
      大分県: "00244",
      宮崎県: "00245",
      鹿児島県: "00246",
      沖縄県: "00247",
      その他: "00248",
    },

    getPrefectureName: function (prefectureKbn) {
      for (var key in this.prefectureHash) {
        if (this.prefectureHash[key] == prefectureKbn) {
          return key;
        }
      }
    },

    // URLのクエリパラメータを取得する汎用メソッド
    getUrlParameter: function (sParam) {
      var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split("&"),
        sParameterName,
        i;
      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split("=");
        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined
            ? true
            : decodeURIComponent(sParameterName[1]);
        }
      }
    },
    // ロケーションパスの1つ目(admin/hospital)を取得
    getBasePath: function () {
      return jQuery(location).attr("pathname").split("/")[1];
    },

    // 文字列をURLエンコードする
    fixedEncodeURIComponent: function (str) {
      return encodeURIComponent(str).replace(/[.-]/g, function (c) {
        return "%" + c.charCodeAt(0).toString(16);
      });
    },
    // NaNの場合は0を返す汎用メソッド
    getZeroForNaN: function (val) {
      val = val.replace(",", "");
      return isNaN(parseInt(val)) ? 0 : parseInt(val);
    },
  };
})();
