const validator = require("./validator");
const expenseForm = require("./expense_form");

(function () {
  // 汎用メソッド定義ここから
  const utils = {
    // フォームを初期化する
    initForms: function () {
      this.toggleImplantColumns($("[data-retreatment-treatment-kbn]"));
      this.toggleRetreatmentTreatmentOther(
        $("[data-retreatment-treatment-kbn]")
      );
      $('[data-trigger="true"]').each((i, e) => {
        this.toggleFormGrps($(e));
      });
      $('[data-other-trigger="true"]').each((i, e) => {
        this.toggleOtherTextField($(e));
      });
    },
    // 引数のelementのチェック有無、テキスト入力有無に応じて、同じ data-group に属するフォームタグを有効・無効化
    toggleFormGrps: function (element) {
      let formgrp = element.data("group");
      let toggle;
      if (element.prop("type") === "checkbox") {
        toggle =
          $(`input[data-group="${formgrp}"]input[data-trigger="true"]:checked`)
            .length > 0;
      } else if (element.prop("type") === "text") {
        toggle =
          $(`input[data-group="${formgrp}"]input[data-trigger="true"]`).val()
            .length > 0;
      }
      $(`[data-group="${formgrp}"]`).each((i, elem) => {
        // data-trigger="true"（トグルをトリガした要素自身）の場合はトグル対象外
        if (!$(elem).data("trigger")) {
          $(elem).prop("disabled", !toggle);
          // フォーム無効に切り替える場合、バリデーションエラーを削除する
          if (!toggle) {
            validator.clearError($(elem));
          }
        }
        // 「その他」テキストフォームについては、無効化時は無効化、有効化時は「その他」ラジオボタンのチェックに応じて分岐
        if (
          $(`[data-group="${formgrp}"][data-other-trigger="true"]`).length > 0
        ) {
          if (!toggle) {
            let elem = $(`[data-group="${formgrp}"][data-other-text="true"]`);
            elem.prop("disabled", true);
          } else {
            utils.toggleOtherTextField(
              $(`[data-group="${formgrp}"][data-other-trigger="true"]`)
            );
          }
        }
      });
    },
    // 引数のelementのラジオボタンの 'その他' のチェックに応じて、同じ data-group に属するその他テキストを有効・無効化
    toggleOtherTextField: function (element) {
      let formgrp = element.data("group");
      let toggle =
        $(
          `[data-group="${formgrp}"][data-other-trigger="true"]:checked`
        ).val() == "other";
      let elem = $(`[data-group="${formgrp}"][data-other-text="true"]`);
      elem.prop("disabled", !toggle);
      if (!toggle) {
        validator.clearError(elem);
      }
    },
    toggleRetreatmentTreatmentOther: function (element) {
      let toggle = element.val() === "other";
      $("[data-retreatment-treatment-other").toggle(toggle);
      if (!toggle) {
        $("[data-retreatment-treatment-other").val("");
      }
    },
    toggleImplantColumns: function (element) {
      let toggle =
        element.val() === "implant" || element.val() === "implant_reembedding";
      $("[data-implant-columns]").toggle(toggle);
      if (!toggle) {
        $("[data-implant-maker]").val("");
        $("[data-implant-product]").val("");
      }
    },
    // インプラントIDから製品を取得
    getImplantProducts: function (implant_maker_id) {
      var self = this;
      var url = `/${Gdt.Util.getBasePath()}/implant_makers/${implant_maker_id}/implant_products/`;
      if (implant_maker_id != "") {
        $("[data-implant-maker]").prop("disabled", true);
        $("[data-implant-product]").prop("disabled", true);
        validator.clearError($("[data-implant-product]"))
        $.ajax({
          type: "get",
          url: url,
          success: function (res) {
            self.setImplantProducts(res);
          },
          complete: function (res) {
            $("[data-implant-maker]").prop("disabled", false);
            if (parseInt($('[data-implant-maker]').val()) === $('[data-implant-product]').data('pontic-id')) {
              $("[data-implant-product]").prop("disabled", true);
            } else {
              $("[data-implant-product]").prop("disabled", false);
            }
          },
        });
      } else {
        //空文字が入った時
        self.deleteImplantProducts();
      }
    },
    // レスポンスから製品をOPTIONSにセット
    setImplantProducts: function (res) {
      let element = $("[data-implant-product]");
      this.deleteImplantProducts();
      for (let k in res) {
        element.append($("<option>").val(res[k].id).text(res[k].title));
      }
      element.val("");
    },
    deleteImplantProducts: function () {
      let element = $("[data-implant-product]");
      element.children().each((i, e) => {
        if ($(e).val()) {
          $(e).remove();
        }
      });
    },
    // 合計金額をアップデート
    setPrice: function (dataPriceTag, value) {
      let span = $(
        `span[data-price-tag="${dataPriceTag}"][data-price-sum="true"]`
      );
      span.data("value", value);
      span.text(`${(value === 0 ? 0 : value).toLocaleString()}円`);
      let hiddenInput = $(
        `input[data-price-tag="${dataPriceTag}"][data-price-sum="true"]`
      );
      if (hiddenInput.length > 0) {
        hiddenInput.val(value);
      }
    },
    // priceTag の価格グループを集計
    calculatePrice: function (priceTag) {
      let sum = 0;
      let val;
      $(`[data-price-tag="${priceTag}"][data-price="true"]`).each((i, e) => {
        if (!$(e).prop("disabled")) {
          val = Gdt.Util.getZeroForNaN($(e).val());
          sum += val;
        }
      });
      this.setPrice(priceTag, sum);
    },
    // 全ての支出の合計金額を集計
    calculatePriceSum: function () {
      if (utils.getModelName() === "implant_retreatment") {
        let upperDentistPriceSum = Gdt.Util.getZeroForNaN(
          $(
            'input[data-price-tag="upper_structure_dentist_price"][data-price-sum="true"]'
          ).val()
        );
        let upperMaterialPriceSum = Gdt.Util.getZeroForNaN(
          $(
            'input[data-price-tag="upper_structure_material_price"][data-price-sum="true"]'
          ).val()
        );
        this.setPrice(
          "upper_structure_retreatment_price",
          upperDentistPriceSum + upperMaterialPriceSum
        );

        let upperPatientBurdenPrice = Gdt.Util.getZeroForNaN(
          $(
            "#implant_retreatment_retreatment_expenses_sum_upper_structure_patient_burden_price"
          ).val()
        );
        let upperSum =
          upperDentistPriceSum +
          upperMaterialPriceSum -
          upperPatientBurdenPrice;
        this.setPrice("upper_structure_final_price", upperSum);

        let implantDentistPriceSum = Gdt.Util.getZeroForNaN(
          $(
            'input[data-price-tag="implant_dentist_price"][data-price-sum="true"]'
          ).val()
        );
        let implantMaterialPriceSum = Gdt.Util.getZeroForNaN(
          $(
            'input[data-price-tag="implant_material_price"][data-price-sum="true"]'
          ).val()
        );
        let implantSum = implantDentistPriceSum + implantMaterialPriceSum;
        this.setPrice("implant_final_price", implantSum);

        let finalSum = upperSum + implantSum;
        this.setPrice("final_price", finalSum);
      } else if (utils.getModelName() === "beauty_retreatment") {
        let beautyDentistPriceSum = Gdt.Util.getZeroForNaN(
          $(
            'input[data-price-tag="beauty_dentistry_dentist_price"][data-price-sum="true"]'
          ).val()
        );
        let beautyMaterialPriceSum = Gdt.Util.getZeroForNaN(
          $(
            'input[data-price-tag="beauty_dentistry_material_price"][data-price-sum="true"]'
          ).val()
        );
        let beautySum = beautyDentistPriceSum + beautyMaterialPriceSum;
        this.setPrice("beauty_dentistry_final_price", beautySum);
      }
    },
    // 全ての支出を集計
    calculatePriceAll: function () {
      if (utils.getModelName() === "implant_retreatment") {
        this.calculatePrice("upper_structure_dentist_price");
        this.calculatePrice("upper_structure_material_price");
        this.calculatePrice("implant_dentist_price");
        this.calculatePrice("implant_material_price");
        this.calculatePriceSum();
      } else if (utils.getModelName() === "beauty_retreatment") {
        this.calculatePrice("beauty_dentistry_dentist_price");
        this.calculatePrice("beauty_dentistry_material_price");
        this.calculatePriceSum();
      }
    },
    // status_kbnを変更する
    setStatusKbn: function (status_kbn) {
      $("[data-status-kbn]").val(status_kbn);
    },
    // 支払のフォームを生成する
    renderExpenseForm: function () {
      
    },
    removeExpenseForm: function () {

    }
  };
  // 汎用メソッド定義ここまで

  // フォームの状態を取得（beforeunload用）
  let originalForm;

  // クラス定義ここから
  Gdt.RetreatmentContentFormView = Marionette.View.extend({
    initialize: function () {
      if ($("[data-page]").val() === "content") {
        utils.initForms();
        validator.init();
        // エラーがある場合、全要素のバリデーションを実行してエラーを表示
        if ($("[data-error]").length) {
          validator.validateAll();
        }
        expenseForm.init();
      }
    },
    ui: {
      selectRetreatmentTreatmentKbn: "[data-retreatment-treatment-kbn]",
      selectImplantMaker: "[data-implant-maker]",
      triggerOfFormGrps: 'input[data-trigger="true"]',
      triggerOfDocumentOther: '[data-other-trigger="true"]',
      prices: '[data-price="true"]',
      btnSubmit: "#btn-submit",
      btnSaveDraft: "#btn-save-draft",
      btnDisableBeforeUnload: '[data-disable-before-unload="true"]',
    },
    // イベントの定義
    events: {
      "change @ui.selectRetreatmentTreatmentKbn":
        "handleChangeRetreatmentTreatmentKbn",
      "change @ui.selectImplantMaker": "handleChangeImplantMaker",
      "change @ui.triggerOfFormGrps": "handleChangeTriggerOfFormGrps",
      "change @ui.triggerOfDocumentOther": "handleChangeTriggerOfDocumentOther",
      "click @ui.btnSubmit": "handleClickBtnSubmit",
      "click @ui.btnSaveDraft": "handleClickBtnSaveDraft",
      'click [data-method="delete"]': "handleClickFileDelete",
    },
    handleChangeRetreatmentTreatmentKbn: function (event) {
      utils.toggleImplantColumns($(event.currentTarget));
      utils.toggleRetreatmentTreatmentOther($(event.currentTarget));
    },
    handleChangeImplantMaker: function (event) {
      utils.getImplantProducts($(event.currentTarget).val());
    },
    handleChangeTriggerOfFormGrps: function (event) {
      utils.toggleFormGrps($(event.currentTarget));
      utils.calculatePriceAll();
    },
    handleChangeTriggerOfDocumentOther: function (event) {
      utils.toggleOtherTextField($(event.currentTarget));
    },
    handleChangePrices: function (event) {
      utils.calculatePrice($(event.currentTarget).data("price-tag"));
      utils.calculatePriceSum();
    },
    // beforeunlodのリスナーをセットする
    bindBeforeUnload: function () {
      if ($("[data-page]").val() === "content") {
        $(window).on("beforeunload", function (e) {
          if (originalForm !== utils.getCurrentFormData()) {
            return "この操作を行うと、行った変更が破棄されます";
          }
        });
      }
    },
    // beforeunloadのリスナーを解除する
    unbindBeforeUnload: function () {
      $(window).unbind("beforeunload");
    },
    handleClickBtnSubmit: function () {
      expenseForm.setPostData();
      if (
        $("[data-page]").val() === "content" &&
        $("[data-status-kbn]").val() === "schedule_approved"
      ) {
        utils.setStatusKbn("content_edit");
        this.unbindBeforeUnload();
        $("form").submit();
      } else if (
        $("[data-page]").val() === "content_2" &&
        $("[data-status-kbn]").val() === "content_edit"
      ) {
        if (window.confirm("再治療内容を登録してよろしいですか？")) {
          utils.setStatusKbn("content_review");
          this.unbindBeforeUnload();
          $("form").submit();
        }
      } else {
        this.unbindBeforeUnload();
        $("form").submit();
      }
    },
    handleClickBtnSaveDraft: function () {
      expenseForm.setPostData();
      if ($("[data-status-kbn]").val() === "content_edit") {
        $("[data-status-kbn]").val("schedule_approved")
      }
      this.unbindBeforeUnload();
      $('input[name="save_draft"]').val("true");
      $("form").submit();
    },
  });
})();
