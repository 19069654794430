(function ($) {
  $.fn.disableSelection = function () {
    return this.attr("unselectable", "on")
      .css("user-select", "none")
      .css("-moz-user-select", "none")
      .css("-khtml-user-select", "none")
      .css("-webkit-user-select", "none")
      .on("selectstart", false)
      .on("contextmenu", false)
      .on("keydown", false)
      .on("mousedown", false);
  };
  $.fn.enableSelection = function () {
    return this.attr("unselectable", "")
      .css("user-select", "")
      .css("-moz-user-select", "")
      .css("-khtml-user-select", "")
      .css("-webkit-user-select", "")
      .off("selectstart", false)
      .off("contextmenu", false)
      .off("keydown", false)
      .off("mousedown", false);
  };
})(jQuery);

(function () {
  var moment = require("moment");
  Gdt.ImplantOperationToothNewView = Marionette.View.extend({
    SECURITY_REQUEST_NOTHING: "02403",

    initialize: function (options) {
      this.url = options.url;
      //implant_planのオブジェクトたちを回収
      this.implantPlansObj = JSON.parse(jQuery("#app-implant-plans").text());
      this.implantOperation = JSON.parse(
        jQuery("#app-implant_operation").text()
      );
      var fixed_flag = jQuery("#app-fixed-flag").data("fixed-flag");
      //保証関連オペであり、かつ請求確定していなければプランおよび金額関係のイニシャライズ
      //請求確定している場合viewで固めてしまう。保証のないオペならそもそもこれらの項目がない。
      if (
        this.implantOperation.security_request_kbn !==
          this.SECURITY_REQUEST_NOTHING &&
        !fixed_flag
      ) {
        this.deleteRadiobutton();
        this.setSecurityMaxPrice();
        this.setDecision();
        this.changeDisabled();
        this.checkImplantPlanRadioButton();
        this.changeImplantPlanSelectBox();
      }
      // 製品番号で検索するフォーム
      let self = this;
      this.handleChangeImplantMaker().then((res) => {
        var implant_maker_id = jQuery(
          "#implant_operation_tooth_implant_maker_id"
        ).val();
        var product_id = jQuery(
          "#implant_operation_tooth_implant_product_id"
        ).val();
        self.handleShowHideSearchByProductNo(implant_maker_id);
        if (product_id != "" && implant_maker_id != "") {
          self.handleChangeProduct();
        }
        //処置歯の画面ではfont sizeを16pxにするらしい;
        document.body.style.fontSize = "16px";
      });
    },

    ui: {
      twiceFlag: "#app-twice-flag",
      selectImplantPlanId: "#implant_operation_tooth_implant_plan_id",
      fullCoverRadioButton: "#app-full-cover-radio-button",
      maker: "#implant_operation_tooth_implant_maker_id",
      product: "#implant_operation_tooth_implant_product_id",
      diameter: "#implant_operation_tooth_implant_diameter_master_detail_id",
      length: "#implant_operation_tooth_implant_length_master_detail_id",
      upperRadioButton: "#app-upper-structure-radio-button",
    },

    events: {
      "click #app-full-cover-radio-button": "setFullCover",
      "click #app-upper-structure-radio-button": "setUpperStructure",
      "change #implant_operation_tooth_implant_plan_id":
        "handleChangeImplantPlan",
      "change #implant_operation_tooth_implant_maker_id":
        "handleChangeImplantMaker",
      'change input[name="inherit-tooth"]': "handleInheritTooth",
      "click #app-implant_operation_tooth-submit": "alertSubmit",
      "click #search_by_product_number_btn": "searchByProductNo",
      "change #search_by_product_number_input": "handleChangeProductNo",
      "change #implant_operation_tooth_implant_product_id":
        "handleChangeProduct",
      "change #implant_operation_tooth_implant_diameter_master_detail_id":
        "handleChangeProductInfo",
      "change #implant_operation_tooth_implant_length_master_detail_id":
        "handleChangeProductInfo",
    },

    // パスから implant_operation_id を取得
    getImplantOperationId: function () {
      var pathArray = jQuery(location).attr("pathname").split("/");
      return pathArray[
        pathArray.findIndex((a) => a === "implant_operations") + 1
      ];
    },

    //インプラントプランのラジオボタンをチェックする
    checkImplantPlanRadioButton: function () {
      var implantPlans = this.implantPlansObj;
      //保障プランのselect boxの値を取得
      var selectedImplantPlanId = this.ui.selectImplantPlanId.val();

      var selectedImplantObj = _.find(implantPlans, function (item) {
        return item.id == selectedImplantPlanId;
      });

      if (typeof selectedImplantObj == "undefined") {
        //何もしない
      } else if (selectedImplantObj.full_cover_flag) {
        this.ui.fullCoverRadioButton.prop("checked", true);
      } else {
        this.ui.upperRadioButton.prop("checked", true);
      }
    },

    // 製品番号で製品を検索する
    searchByProductNo: function () {
      var self = this;
      //インプラントメーカーのidを取得
      var implant_maker_id = jQuery(
        "#implant_operation_tooth_implant_maker_id"
      ).val();
      //製品番号を取得
      var product_number = jQuery("#search_by_product_number_input").val();
      product_number = Gdt.Util.fixedEncodeURIComponent(product_number);
      if (product_number != "" && implant_maker_id != "") {
        var url = `/${Gdt.Util.getBasePath()}/implant_makers/${implant_maker_id}/implant_products/search_by_product_number`;
        jQuery.ajax({
          type: "get",
          url: url,
          data: { product_number: product_number },
          success: function (res) {
            if (res) {
              jQuery("#search_by_product_number_err").hide();
              $("#implant_operation_tooth_implant_product_id option")
                .filter(function () {
                  return $(this).text() === res.title;
                })
                .prop("selected", true);
              jQuery(
                "#implant_operation_tooth_implant_diameter_master_detail_id"
              ).val(res.implant_diameter_master_detail_id);
              jQuery(
                "#implant_operation_tooth_implant_length_master_detail_id"
              ).val(res.implant_length_master_detail_id);
              self.disabledEnabledProductSearchSelectBox(true);
            } else {
              jQuery("#search_by_product_number_err").show();
            }
          },
        });
      } else {
        //空文字が入った時
        jQuery("#search_by_product_number_err").show();
      }
    },

    initializeDiameterLength: function () {
      var implant_maker_id = jQuery(
        "#implant_operation_tooth_implant_maker_id"
      ).val();
      let selected_diameter_id = $(
        "#implant_operation_tooth_implant_diameter_master_detail_id"
      ).val();
      let selected_length_id = $(
        "#implant_operation_tooth_implant_length_master_detail_id"
      ).val();
      if (implant_maker_id === null || implant_maker_id === "")
        return Promise.resolve();
      $("#implant_operation_tooth_implant_diameter_master_detail_id").html("");
      $("#implant_operation_tooth_implant_length_master_detail_id").html("");
      var url = `/${Gdt.Util.getBasePath()}/implant_makers/${implant_maker_id}/implant_products/get_diameter_lengths`;
      return jQuery
        .ajax({
          type: "get",
          url: url,
          data: {},
        })
        .then(
          //通信成功時の処理
          function (res) {
            var diameters = res.implant_diameters;
            for (var i = 0; i < diameters.length; i++) {
              $(
                "#implant_operation_tooth_implant_diameter_master_detail_id"
              ).append(
                $("<option>").val(diameters[i][1]).text(diameters[i][0])
              );
            }
            $("#implant_operation_tooth_implant_diameter_master_detail_id").val(
              selected_diameter_id
            );
            var lengths = res.implant_lengths;
            for (var i = 0; i < lengths.length; i++) {
              $(
                "#implant_operation_tooth_implant_length_master_detail_id"
              ).append($("<option>").val(lengths[i][1]).text(lengths[i][0]));
            }
            $("#implant_operation_tooth_implant_length_master_detail_id").val(
              selected_length_id
            );
          },
          //通信失敗時の処理
          function () {
            alert("通信に失敗しました");
          }
        );
    },

    handleChangeProduct: function () {
      var self = this;
      self.handleChangeProductInfo();
      self.initializeDiameterLength().then(function () {
        // 直径長さのセレクトを有効にする
        jQuery(
          "#implant_operation_tooth_implant_diameter_master_detail_id"
        ).enableSelection();
        jQuery(
          "#implant_operation_tooth_implant_length_master_detail_id"
        ).enableSelection();
        // 製品番号フォームがある場合
        if (jQuery("#search_by_product_number_tr").is(":visible")) {
          //インプラントメーカーのidを取得
          var implant_maker_id = jQuery(
            "#implant_operation_tooth_implant_maker_id"
          ).val();
          var product_id = self.ui.product.val();
          if (product_id != "" && implant_maker_id != "") {
            var url = `/${Gdt.Util.getBasePath()}/implant_makers/${implant_maker_id}/implant_products/search_by_product_title`;
            jQuery.ajax({
              type: "get",
              url: url,
              data: {
                product_title: jQuery(
                  "#implant_operation_tooth_implant_product_id option:selected"
                ).text(),
              },
              success: function (res) {
                // すでに直径と長さが選択されている場合、再度セットする
                let selected_diameter_id = $(
                  "#implant_operation_tooth_implant_diameter_master_detail_id"
                ).val();
                let selected_length_id = $(
                  "#implant_operation_tooth_implant_length_master_detail_id"
                ).val();
                if (res.length === 1) {
                  // 製品名が重複無しの場合、直径長さを自動でセット
                  if (res[0].implant_diameter_master_detail_id !== null) {
                    jQuery(
                      "#implant_operation_tooth_implant_diameter_master_detail_id"
                    ).val(res[0].implant_diameter_master_detail_id);
                    jQuery(
                      "#implant_operation_tooth_implant_diameter_master_detail_id"
                    ).disableSelection();
                  }
                  if (res[0].implant_length_master_detail_id !== null) {
                    jQuery(
                      "#implant_operation_tooth_implant_length_master_detail_id"
                    ).val(res[0].implant_length_master_detail_id);
                    jQuery(
                      "#implant_operation_tooth_implant_length_master_detail_id"
                    ).disableSelection();
                  }
                } else if (res.length > 1) {
                  // 製品名に重複がある場合、同じ製品名に指定されている直径、長さで一覧をフィルターする
                  var diameter_ops = "option";
                  var length_ops = "option";
                  for (var i = 0; i < res.length; i++) {
                    if (res[i].implant_diameter_master_detail_id !== null) {
                      diameter_ops +=
                        '[value!="' +
                        res[i].implant_diameter_master_detail_id +
                        '"]';
                    }
                    if (res[i].implant_length_master_detail_id !== null) {
                      length_ops +=
                        '[value!="' +
                        res[i].implant_length_master_detail_id +
                        '"]';
                    }
                  }
                  if (diameter_ops !== "option") {
                    jQuery(
                      "#implant_operation_tooth_implant_diameter_master_detail_id"
                    )
                      .find(diameter_ops)
                      .remove();
                    jQuery(
                      "#implant_operation_tooth_implant_diameter_master_detail_id"
                    ).prepend($("<option>").val("").text("選択してください"));
                  }
                  if (length_ops !== "option") {
                    jQuery(
                      "#implant_operation_tooth_implant_length_master_detail_id"
                    )
                      .find(length_ops)
                      .remove();
                    jQuery(
                      "#implant_operation_tooth_implant_length_master_detail_id"
                    ).prepend($("<option>").val("").text("選択してください"));
                  }
                  $(
                    "#implant_operation_tooth_implant_diameter_master_detail_id"
                  ).val(
                    res
                      .filter((v) => v.implant_diameter_master_detail_id)
                      .map((v) =>
                        v.implant_diameter_master_detail_id.toString()
                      )
                      .includes(selected_diameter_id)
                      ? selected_diameter_id
                      : ""
                  );
                  $(
                    "#implant_operation_tooth_implant_length_master_detail_id"
                  ).val(
                    res
                      .filter((v) => v.implant_length_master_detail_id)
                      .map((v) => v.implant_length_master_detail_id.toString())
                      .includes(selected_length_id)
                      ? selected_length_id
                      : ""
                  );
                }
              },
            });
          }
        }
      });
    },

    handleChangeProductInfo: function () {
      jQuery("#search_by_product_number_err").hide();
    },

    handleChangeProductNo: function () {
      jQuery("#search_by_product_number_err").hide();
      this.disabledEnabledProductSearchSelectBox(false);
    },

    disabledEnabledProductSearchSelectBox: function (disabled) {
      if (disabled) {
        jQuery(
          "#implant_operation_tooth_implant_product_id"
        ).disableSelection();
        jQuery(
          "#implant_operation_tooth_implant_diameter_master_detail_id"
        ).disableSelection();
        jQuery(
          "#implant_operation_tooth_implant_length_master_detail_id"
        ).disableSelection();
      } else {
        jQuery("#implant_operation_tooth_implant_product_id").enableSelection();
        jQuery(
          "#implant_operation_tooth_implant_diameter_master_detail_id"
        ).enableSelection();
        jQuery(
          "#implant_operation_tooth_implant_length_master_detail_id"
        ).enableSelection();
      }
    },

    handleChangeImplantMaker: function () {
      //インプラントメーカーのidを取得
      var implant_maker_id = jQuery(
        "#implant_operation_tooth_implant_maker_id"
      ).val();
      this.filterImplantPlanByMaker(implant_maker_id);
      this.getImplantProducts(implant_maker_id);
      this.handleShowHideSearchByProductNo(implant_maker_id);
      this.disabledEnabledProductSearchSelectBox(false);
      return this.initializeDiameterLength();
    },

    // 現在選択中のメーカーで、利用可能なプランをフィルタする
    filterImplantPlanByMaker: function (implant_maker_id) {
      var self = this;
      var url = `/${Gdt.Util.getBasePath()}/implant_plans/search_implant_plan_by_maker_id`;
      var operation_id = jQuery(location).attr("pathname").split("/")[3];
      if (implant_maker_id !== "") {
        $.ajax({
          type: "GET",
          url: url,
          data: {
            implant_maker_id: implant_maker_id,
            operation_id: operation_id,
          },
        }).then(function (res) {
          if (res.length > 0) {
            // 延長プランが後ろになるようにソート
            res.sort(
              (a, b) => a.extended_warranty_flag - b.extended_warranty_flag
            );
            self.setImplantPlans(res);
          } else {
            let selected_plan = self.ui.selectImplantPlanId.val();
            self.ui.upperRadioButton.prop("checked")
              ? self.setUpperStructure(selected_plan)
              : self.setFullCover(selected_plan);
          }
        });
      }
    },

    setImplantPlans: function (res) {
      let selectedPlanId = parseInt(this.ui.selectImplantPlanId.val());
      this.ui.fullCoverRadioButton.prop("checked", true);
      this.setFullCover();
      this.deleteImplantPlans();
      for (var i = 0; i < res.length; i++) {
        $("select#implant_operation_tooth_implant_plan_id").append(
          $("<option>").val(res[i].id).text(res[i].name)
        );
      }
      if (
        selectedPlanId !== "" &&
        res.map((v) => v.id).includes(selectedPlanId)
      ) {
        this.ui.selectImplantPlanId.val(selectedPlanId);
      } else {
        $("select#implant_operation_tooth_implant_plan_id").val(res[0].id);
      }

      this.handleChangeImplantPlan(false);
    },

    deleteImplantPlans: function () {
      $("select#implant_operation_tooth_implant_plan_id option").remove();
      $("select#implant_operation_tooth_implant_plan_id").append(
        $("<option>").val("").text("選択してください")
      );
    },

    handleShowHideSearchByProductNo: function (implant_maker_id) {
      if (implant_maker_id != "") {
        var url = `/${Gdt.Util.getBasePath()}/implant_makers/${implant_maker_id}/check_product_number_search_flag`;
        jQuery.ajax({
          type: "get",
          url: url,
          data: {},
          success: function (res) {
            if (res.product_number_search_flag) {
              jQuery("#search_description").text(
                res.search_description ? res.search_description : ""
              );
              jQuery("#search_by_product_number_tr").show();
            } else {
              jQuery("#search_by_product_number_tr").hide();
            }
          },
        });
      } else {
        jQuery("#search_by_product_number_tr").hide();
      }
    },

    getImplantProducts: function (implant_maker_id) {
      var self = this;
      var url = this.url.replace(":implant_maker_id:", implant_maker_id); //adminのときはreplaceされる
      if (implant_maker_id != "") {
        return jQuery.ajax({
          type: "get",
          url: url,
          data: { implant_maker_id: implant_maker_id },
          success: function (res) {
            self.setImplantProducts(res);
          },
        });
      } else {
        //空文字が入った時
        self.deleteImplantProducts();
      }
    },

    setImplantProducts: function (res) {
      let selected_product_id = $(
        "#implant_operation_tooth_implant_product_id"
      ).val();
      this.deleteImplantProducts();
      for (var k in res) {
        jQuery("#implant_operation_tooth_implant_product_id").append(
          $("<option>").val(res[k].id).text(res[k].title)
        );
      }
      $("#implant_operation_tooth_implant_product_id").val(selected_product_id);
    },

    deleteImplantProducts: function () {
      jQuery(
        "select#implant_operation_tooth_implant_product_id option"
      ).remove();
      jQuery("#implant_operation_tooth_implant_product_id").append(
        $("<option>").val("").text("選択してください")
      );
    },

    //
    setFullCover: function (choiceId) {
      // 保証プランではラジオを押すまで、コンボは押せないようにする
      this.ui.selectImplantPlanId.prop("disabled", false);

      this.deleteSecurityMaxPrice();
      //implant_planのオブジェクトたちを回収
      var objs = this.implantPlansObj;
      jQuery("select#implant_operation_tooth_implant_plan_id option").remove();
      //select boxの中を全削除
      jQuery("#implant_operation_tooth_implant_plan_id").append(
        $("<option>").val("").text("選択してください")
      );
      for (var i = 0; i < objs.length; i++) {
        if (objs[i].full_cover_flag) {
          jQuery("#implant_operation_tooth_implant_plan_id").append(
            $("<option>").val(objs[i].id).text(objs[i].implant_plan_name)
          );
        }
      }
      // choiceId が何かあればプランを選択して保証限度額もセットする
      if (typeof choiceId === "string") {
        jQuery("#implant_operation_tooth_implant_plan_id").val(
          choiceId.toString()
        );
        this.setSecurityMaxPrice();
      }
      this.changeDisabled();
    },

    //フルカバーのselect boxを隠す
    setUpperStructure: function (choiceId) {
      // 保証プランではラジオを押すまで、コンボは押せないようにする
      this.ui.selectImplantPlanId.prop("disabled", false);

      this.deleteSecurityMaxPrice();
      //implant_planのオブジェクトたちを回収
      var objs = this.implantPlansObj;
      //select boxの中を全削除
      jQuery("select#implant_operation_tooth_implant_plan_id option").remove();
      jQuery("#implant_operation_tooth_implant_plan_id").append(
        $("<option>").val("").text("選択してください")
      );
      for (var i = 0; i < objs.length; i++) {
        if (!objs[i].full_cover_flag) {
          jQuery("#implant_operation_tooth_implant_plan_id").append(
            $("<option>").val(objs[i].id).text(objs[i].implant_plan_name)
          );
        }
      }
      // choiceIdが何かあればそれを選択する
      if (typeof choiceId === "string") {
        jQuery("#implant_operation_tooth_implant_plan_id").val(
          choiceId.toString()
        );
      }
      //インプラントメーカー,製品,直径の入力を無効にする
      this.changeDisabled();
    },

    handleChangeImplantPlan: function (resetMaker = true) {
      if (this.ui.selectImplantPlanId.val() !== "") {
        // プランの属するメーカーをセットする
        const plan_id = jQuery(
          "#implant_operation_tooth_implant_plan_id"
        ).val();
        if (plan_id) {
          const self = this;
          this.setImplantMakersByPlan(plan_id).then(function () {
            // 保証限度額をセット
            // 「上部構造のみ」プランの場合、メーカーにポンティックを自動設定
            self.setSecurityMaxPrice();
            // 製品番号検索ボックスの切り替え
            var implant_maker_id = jQuery(
              "#implant_operation_tooth_implant_maker_id"
            ).val();
            self.handleShowHideSearchByProductNo(implant_maker_id);
            // 5年延長保証の場合、保証情報引き継ぎフォームを表示
            self.toggleToothInheritForm(plan_id);
          });
        }
      }
      if (resetMaker) {
        self.resetMaker();
      }
    },

    // メーカー、製品、直径、長さを未選択の状態にする
    resetMaker: function () {
      this.ui.maker.val("");
      this.ui.product.val("");
      this.ui.diameter.val("");
      this.ui.length.val("");
      this.handleShowHideSearchByProductNo("");
    },

    //保証プランのselct boxに何かセットされたところで保証限度額をセット
    setSecurityMaxPrice: function () {
      //radiobutton の状態を変化させる
      this.checkImplantPlanRadioButton();

      var self = this;
      //まずは保証プランにセットされたidを取得
      var implantPlanId = jQuery(
        "#implant_operation_tooth_implant_plan_id"
      ).val();
      var objs = this.implantPlansObj;
      //implantPlanIdに空文字が代入される場合([選択してください])限度額を空にする
      if (implantPlanId != "") {
        for (var i = 0; i < objs.length; i++) {
          if (implantPlanId == objs[i].id) {
            if (Math.trunc(objs[i].implant_security_max_price) == 0) {
              jQuery("#app-implant-price").text("-");
            } else {
              jQuery("#app-implant-price").text(
                Math.trunc(objs[i].implant_security_max_price) + "円"
              );
            }
            if (Math.trunc(objs[i].upper_structure_security_max_price) == 0) {
              jQuery("#app-upper-structure-price").text("-");
            } else {
              jQuery("#app-upper-structure-price").text(
                Math.trunc(objs[i].upper_structure_security_max_price) + "円"
              );
            }
          }
        }
      } else {
        jQuery("#app-implant-price").text("");
        jQuery("#app-upper-structure-price").text("");
      }

      //ついでに確定金額も出力しておく
      self.setDecision();
      self.changeDisabled();
    },

    // プランでメーカーをフィルタする
    setImplantMakersByPlan(id) {
      if (id == "") return;
      self = this;
      var url = `/${Gdt.Util.getBasePath()}/implant_makers/search_implant_maker_by_plan_id`;

      return jQuery
        .ajax({
          type: "get",
          url: url,
          dataType: "json",
          data: { implant_plan_id: id },
        })
        .then(
          //通信成功時の処理
          function (res) {
            // 現在選択中のメーカーを保持しておき、リスト更新後にセットする
            let selected_maker = jQuery(
              "#implant_operation_tooth_implant_maker_id option:selected"
            )
              .val()
              .toString();
            self.ui.maker.html("");
            self.ui.maker.append("<option value=''>選択してください</option>");
            _.each(res, function (item) {
              self.ui.maker.append(
                "<option value='" + item.id + "' >" + item.title + "</option>"
              );
            });
            // 選択済みのメーカーが取得結果にあればセット、なければメーカー、製品名、直径、サイズを未選択に
            // 製品番号検索フォームも非表示にする
            let id_array = res.map((v) => v.id.toString());
            if (id_array.some((i) => i === selected_maker)) {
              jQuery("#implant_operation_tooth_implant_maker_id").val(
                selected_maker
              );
            } else {
              jQuery("#implant_operation_tooth_implant_maker_id").val("");
              jQuery("#implant_operation_tooth_implant_product_id").val("");
              jQuery(
                "#implant_operation_tooth_implant_diameter_master_detail_id"
              ).val("");
              jQuery(
                "#implant_operation_tooth_implant_length_master_detail_id"
              ).val("");
              jQuery("#search_by_product_number_tr").hide();
            }
          },
          //通信失敗時の処理
          function () {
            alert("通信に失敗しました");
          }
        );
    },

    // 保証情報引き継ぎフォームの切り替え
    toggleToothInheritForm: function (plan_id) {
      jQuery("#inherit-tooth-err").text("");
      jQuery("#inherit-tooth-err").hide();
      if (plan_id !== "") {
        const self = this;
        const url = `/${Gdt.Util.getBasePath()}/implant_plans/${plan_id}/is_extended_warranty`;
        return jQuery
          .ajax({
            type: "get",
            url: url,
            dataType: "json",
          })
          .then(
            //通信成功時の処理
            function (res) {
              if (res) {
                jQuery("#tr-inherit-tooth").show();
              } else {
                jQuery("#tr-inherit-tooth").hide();
                jQuery("#app-inherit-no-radio-button").prop("checked", true);
                // self.clearToothFormValues();
              }
            },
            //通信失敗時の処理
            function () {
              alert("通信に失敗しました");
            }
          );
      } else {
        jQuery("#tr-inherit-tooth").hide();
        jQuery("#app-inherit-no-radio-button").prop("checked", true);
      }
    },

    // フォームの要素、テキスト取得、デフォルト値セット、toothオブジェクトに基づく値セットのメソッドを返す
    getFormObjects: function () {
      let is_hospital = Gdt.Util.getBasePath() === "hospital";
      var forms = {
        implant_maker_id: {
          element: jQuery("#implant_operation_tooth_implant_maker_id"),
          text: () =>
            jQuery(
              "#implant_operation_tooth_implant_maker_id option:selected"
            ).text(),
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(tooth.implant_maker_id);
          },
        },
        implant_product_id: {
          element: jQuery("#implant_operation_tooth_implant_product_id"),
          text: () =>
            jQuery(
              "#implant_operation_tooth_implant_product_id option:selected"
            ).text(),
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(tooth.implant_product_id);
          },
        },
        implant_diameter_master_detail_id: {
          element: jQuery(
            "#implant_operation_tooth_implant_diameter_master_detail_id"
          ),
          text: () =>
            jQuery(
              "#implant_operation_tooth_implant_diameter_master_detail_id option:selected"
            ).text(),
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(tooth.implant_diameter_master_detail_id);
          },
        },
        implant_length_master_detail_id: {
          element: jQuery(
            "#implant_operation_tooth_implant_length_master_detail_id"
          ),
          text: () =>
            jQuery(
              "#implant_operation_tooth_implant_length_master_detail_id option:selected"
            ).text(),
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(tooth.implant_length_master_detail_id);
          },
        },
        implant_price: {
          element: jQuery("#implant_operation_tooth_implant_price"),
          text: () => jQuery("#implant_operation_tooth_implant_price").val(),
          setDefault: function () {
            this.element.val(0);
          },
          setValue: function (tooth) {
            this.element.val(parseInt(tooth.implant_price));
          },
        },
        lot_code: {
          element: jQuery("#implant_operation_tooth_lot_code"),
          text: () => jQuery("#implant_operation_tooth_lot_code").val(),
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(tooth.lot_code);
          },
        },
        middle_structure_master_detail_id: {
          element: jQuery(
            "#implant_operation_tooth_middle_structure_master_detail_id"
          ),
          text: () =>
            jQuery(
              "#implant_operation_tooth_middle_structure_master_detail_id option:selected"
            ).text(),
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(
              tooth.middle_structure_master_detail_id
                ? tooth.middle_structure_master_detail_id
                : ""
            );
          },
        },
        upper_structure_type_master_detail_id: {
          element: jQuery(
            "#implant_operation_tooth_upper_structure_type_master_detail_id"
          ),
          text: () =>
            jQuery(
              "#implant_operation_tooth_upper_structure_type_master_detail_id option:selected"
            ).text(),
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(
              tooth.upper_structure_type_master_detail_id
                ? tooth.upper_structure_type_master_detail_id
                : ""
            );
          },
        },
        upper_structure_material_master_detail_id: {
          element: jQuery(
            "#implant_operation_tooth_upper_structure_material_master_detail_id"
          ),
          text: () =>
            jQuery(
              "#implant_operation_tooth_upper_structure_material_master_detail_id option:selected"
            ).text(),
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(
              tooth.upper_structure_material_master_detail_id
                ? tooth.upper_structure_material_master_detail_id
                : ""
            );
          },
        },
        upper_structure_attaching_method_master_detail_id: {
          element: jQuery(
            "#implant_operation_tooth_upper_structure_attaching_method_master_detail_id"
          ),
          text: () =>
            jQuery(
              "#implant_operation_tooth_upper_structure_attaching_method_master_detail_id option:selected"
            ).text(),
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(
              tooth.upper_structure_attaching_method_master_detail_id
                ? tooth.upper_structure_attaching_method_master_detail_id
                : ""
            );
          },
        },
        upper_structure_price: {
          element: jQuery("#implant_operation_tooth_upper_structure_price"),
          text: () =>
            jQuery("#implant_operation_tooth_upper_structure_price").val(),
          setDefault: function () {
            this.element.val(0);
          },
          setValue: function (tooth) {
            this.element.val(parseInt(tooth.upper_structure_price));
          },
        },
        using_abutment_flag: {
          element: jQuery("#implant_operation_tooth_using_abutment_flag"),
          text: () =>
            jQuery(
              "#implant_operation_tooth_using_abutment_flag option:selected"
            ).text(),
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(tooth.using_abutment_flag ? "true" : "false");
          },
        },
        abutment_price: {
          element: jQuery("#implant_operation_tooth_abutment_price"),
          text: () => jQuery("#implant_operation_tooth_abutment_price").val(),
          setDefault: function () {
            this.element.val(0);
          },
          setValue: function (tooth) {
            this.element.val(parseInt(tooth.abutment_price));
          },
        },
        remark_content: {
          element: jQuery("#implant_operation_tooth_remark_content"),
          text: () => jQuery("#implant_operation_tooth_remark_content").val(),
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(tooth.remark_content);
          },
        },
        tooth_treat_id: {
          element: jQuery("#implant_operation_tooth_tooth_treat_id"),
          text: () =>
            is_hospital
              ? jQuery(
                  "#implant_operation_tooth_tooth_treat_id option:selected"
                ).text()
              : "",
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(tooth.tooth_treat_id);
          },
        },
        treat_2_master_detail_id_ary: {
          element: jQuery("#span-treat-2"),
          text: () =>
            is_hospital
              ? jQuery(
                  'input[name="implant_operation_tooth[treat_2_master_detail_id_ary][]"]:checked'
                )
                  .map((i, e) => jQuery(e).next("span").text().trim())
                  .get()
                  .join(", ")
              : "",
          setDefault: function () {
            jQuery(
              'input[name="implant_operation_tooth[treat_2_master_detail_id_ary][]"]'
            ).each(function () {
              jQuery(this).prop("checked", false);
            });
          },
          setValue: function (tooth) {
            jQuery(
              'input[name="implant_operation_tooth[treat_2_master_detail_id_ary][]"]'
            ).each(function () {
              if (
                tooth.treat_2_master_detail_id_ary.includes(
                  Number(jQuery(this).val())
                )
              ) {
                jQuery(this).prop("checked", true);
              }
            });
          },
        },
        fixture_id: {
          element: jQuery("#implant_operation_tooth_fixture_id"),
          text: () =>
            is_hospital
              ? jQuery(
                  "#implant_operation_tooth_fixture_id option:selected"
                ).text()
              : "",
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(tooth.fixture_id);
          },
        },
        bone_prosthetic_material_master_detail_id: {
          element: jQuery(
            "#implant_operation_tooth_bone_prosthetic_material_master_detail_id"
          ),
          text: () =>
            is_hospital
              ? jQuery(
                  "#implant_operation_tooth_bone_prosthetic_material_master_detail_id option:selected"
                ).text()
              : "",
          setDefault: function () {
            this.element.val("");
          },
          setValue: function (tooth) {
            this.element.val(tooth.bone_prosthetic_material_master_detail_id);
          },
        },
      };
      return forms;
    },
    // 延長保証元の情報からフォーム値を設定し、spanタグに変換して固定
    setToothFormValues: function (tooth) {
      const formProps = this.getFormObjects();
      for (let key in formProps) {
        formProps[key].setValue(tooth);
        formProps[key].element.hide();
        // '選択してください' が選択されている場合、表示テキストを '選択なし' に変換
        var text =
          formProps[key].text() === "選択してください"
            ? "選択なし"
            : formProps[key].text();
        formProps[key].element.after(`<span id=settled-${key}>${text}</span>`);
      }
    },
    // フォーム入力値をクリアする
    clearToothFormValues: function () {
      const formProps = this.getFormObjects(
        Gdt.Util.getBasePath() === "hospital"
      );
      for (let key in formProps) {
        formProps[key].setDefault();
        formProps[key].element.show();
        jQuery(`#settled-${key}`).remove();
      }
    },

    // 保証情報引き継ぎ
    handleInheritTooth: function () {
      if (jQuery("#app-inherit-yes-radio-button").prop("checked")) {
        const self = this;
        const url = `/${Gdt.Util.getBasePath()}/implant_operations/${this.getImplantOperationId()}/original_tooth_for_extended_warranty`;
        return jQuery
          .ajax({
            type: "get",
            url: url,
            dataType: "json",
            data: {
              tooth_id: jQuery("#implant_operation_tooth_tooth_id").val(),
            },
          })
          .then(
            //通信成功時の処理
            function (res) {
              const tooth = res.org_implant_operation_tooth;
              self.getImplantProducts(tooth.implant_maker_id).then(function () {
                self.setToothFormValues(tooth);
                jQuery("#search_by_product_number_tr").hide();
              });
            },
            //通信失敗時の処理
            function (res) {
              if (res.status === 400) {
                jQuery("#inherit-tooth-err").text(res.responseJSON.msg);
                jQuery("#inherit-tooth-err").show();
              } else {
                console.log(res);
                alert("通信に失敗しました");
              }
            }
          );
      } else {
        this.clearToothFormValues();
        jQuery("#search_by_product_number_tr").hide();
      }
    },

    setDecision: function () {
      var self = this;
      //オブジェクトたちを回収
      var objs = this.implantPlansObj;
      var taxRate = this.implantOperation.tax_rate;
      //まずは保証プランにセットされたidを取得
      var implantPlanId = jQuery(
        "#implant_operation_tooth_implant_plan_id"
      ).val();
      if (implantPlanId != "") {
        for (var i = 0; i < objs.length; i++) {
          if (implantPlanId == objs[i].id) {
            var implantSecurityPrice = Math.trunc(
              objs[i].implant_security_price
            );
            jQuery("#app-decision-price").text(
              String(implantSecurityPrice) +
                "　(" +
                String(Math.trunc(implantSecurityPrice * taxRate)) +
                ")円"
            );
            $("#app-hidden-decision-price").val(implantSecurityPrice);
            $("#app-hidden-decision-price-with-tax").val(
              implantSecurityPrice * taxRate
            );
          }
        }
      } else {
        jQuery("#app-decision-price").text("");
      }
    },

    deleteSecurityMaxPrice: function () {
      jQuery("#app-implant-price").text("");
      jQuery("#app-upper-structure-price").text("");
    },

    disabledProductsDiameterLengthPontic: function (flag) {
      //falseならば有効か trueなら無効化
      if (flag) {
        //無効化するのならば、無効化されるselect boxを[選択してください]にする
        jQuery("#implant_operation_tooth_implant_product_id").val("");
        jQuery(
          "#implant_operation_tooth_implant_diameter_master_detail_id"
        ).val("");
        jQuery("#implant_operation_tooth_implant_length_master_detail_id").val(
          ""
        );
        //必須マークを消す
        jQuery(".app-hissu-mark").remove();
        //ポンティックをset
        this.applyPontic();
      } else {
        if (!jQuery(".app-hissu-mark").length) {
          jQuery(".app-hissu").append(
            "<span class='hissu app-hissu-mark'>*</span>"
          );
        }
      }
      //製品 直径 長さのselect boxを有効化or無効化
      jQuery("#implant_operation_tooth_implant_product_id").prop(
        "disabled",
        flag
      );
      jQuery("#implant_operation_tooth_implant_diameter_master_detail_id").prop(
        "disabled",
        flag
      );
      jQuery("#implant_operation_tooth_implant_length_master_detail_id").prop(
        "disabled",
        flag
      );
      this.ui.maker.prop("disabled", flag);
    },

    changeDisabled: function () {
      //プランが選択された時に、製品 直径 長さのselect boxを有効化or無効化を判定
      //まずは保証プランのselect boxのvalを取得
      var implant_plan_id = jQuery(
        "#implant_operation_tooth_implant_plan_id"
      ).val();
      var implant_plan = _.find(this.implantPlansObj, function (obj) {
        return obj.id == implant_plan_id;
      });
      if (_.isEmpty(implant_plan)) {
        this.disabledProductsDiameterLengthPontic(false);
      } else {
        this.disabledProductsDiameterLengthPontic(
          !implant_plan.full_cover_flag
        );
      }
    },

    applyPontic: function () {
      var ponTicElement = jQuery(
        "#implant_operation_tooth_implant_maker_id option"
      ).filter(function (index, elem) {
        return jQuery(elem).text() == "ポンティック";
      });

      //ポンティックが見つかった時のみ実行
      if (ponTicElement.val()) {
        jQuery("#implant_operation_tooth_implant_maker_id").val(
          ponTicElement.val()
        );
        jQuery("#search_by_product_number_tr").hide();
      }
    },

    // 00501: 通常 02: 光 03: 有心会
    deleteRadiobutton: function () {
      //オペのインプラントプラン区分を取得
      var implantPlanKbn = jQuery("#app-implant-plan-kbn").data("kbn");
      if (implantPlanKbn == "00502") {
        //オペが光区分ならば、プランはフルカバーしかこないので、radio buttonを削除
        jQuery("#app-implant-operation-tooth-full-cover").remove();
        jQuery("#app-implant-operation-tooth-upper-structure").remove();
      }
    },

    changeImplantPlanSelectBox: function () {
      // 保証プランではラジオを押すまで、コンボは押せないようにする
      var implantPlanKbn = jQuery("#app-implant-plan-kbn").data("kbn");
      // 00502=光区分  光区分のときはradio buttonが存在しないので
      // selectBoxもdisableにする必要がない
      if (
        implantPlanKbn !== "00502" &&
        this.ui.selectImplantPlanId.val() === ""
      ) {
        this.ui.selectImplantPlanId.prop("disabled", true);
      } else {
        var selectedImplantPlanId = this.ui.selectImplantPlanId.val();
        var selectedImplantPlan = _.find(this.implantPlansObj, function (obj) {
          return obj.id == selectedImplantPlanId;
        });
        if (selectedImplantPlan.full_cover_flag) {
          this.setFullCover(selectedImplantPlanId);
        } else {
          this.setUpperStructure(selectedImplantPlanId);
        }
      }
    },

    alertSubmit: function () {
      var operatorAtPassFlag = jQuery("#app-implant-operation-pass-flag").data(
        "operation-pass-flag"
      );
      var twiceFlag = this.ui.twiceFlag.data("twice-flag");
      // オペ実施日時と選択された保証プランの免責期間(月数)、現在日時を取得
      var operation_at = moment(
        this.implantOperation.operation_at,
        "YYYY-MM-DD"
      );
      var objs = this.implantPlansObj;
      var implantPlanId = jQuery(
        "#implant_operation_tooth_implant_plan_id"
      ).val();
      for (var i = 0; i < objs.length; i++) {
        if (implantPlanId == objs[i].id) {
          var selectImplantPlanDeductiblePeriod =
            objs[i].deductible_period_month_count;
        }
      }
      var now = moment();
      //オペ後日数の免責期間過半経過フラグ
      //現在日時-オペ日 >= 免責期間/2 なら免責期間の半分以上経過しているとしてtrueを返す。
      // ただし、選択されたプランの免責期間が0ヶ月の場合false。
      var overHalfOfDeductioblePeriodFlag =
        selectImplantPlanDeductiblePeriod == 0
          ? false
          : now.diff(operation_at, "months", true) >=
            selectImplantPlanDeductiblePeriod / 2;
      var confirm_str = "";
      confirm_str = !operatorAtPassFlag
        ? "オペ・治療の予定日を過ぎていません。内容を確認後の登録ですか？\n"
        : "";
      confirm_str =
        confirm_str +
        (twiceFlag
          ? "一本の歯に複数のオペを登録しようとしています。よろしいですか？\n"
          : "");
      const imagesPresent = jQuery('#app-data').data('images-present');
      const rejoin = jQuery('#app-data').data('rejoin');
      confirm_str =
        confirm_str +
        (!imagesPresent && !rejoin && overHalfOfDeductioblePeriodFlag ? "「画像登録」から画像を登録してください。" : "");
      if (confirm_str !== "") {
        if (!confirm(confirm_str)) {
          return false;
        }
      }
      // 二度押し防止
      if (checkDouble()) {
        //submitの前にmakerのdisabledを解除する(disabledだと送信されないため)
        this.ui.maker.prop("disabled", false);
        // 実際のサブミット
        jQuery("form").submit();
      }
    },
  });
})();
